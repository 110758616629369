import { getNearestMinutes } from "../../../lib/util/common/utils";
class DateRangeDTO {
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  hasSameTime: boolean;
  timeRange: {
    date: Date;
    startTime: Date;
    endTime: Date;
  }[]
  constructor(data: any = {}) {
    this.startDate = data.startDate || null;
    this.endDate = data.endDate || null;
    this.startTime = data.startTime || getNearestMinutes(new Date());
    this.endTime =
      data.endTime ||
      new Date(new Date().setMinutes(this.startTime.getMinutes() + 30));
    this.hasSameTime = data.hasSameTime || true;
    this.timeRange = data.timeRange || [
      { date: new Date(), startTime: new Date(), endTime: new Date() },
    ];
  }
}
export default DateRangeDTO;
