import { Button, Box, Card, Stack, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import Trravo from "../../assets/trravo.png";

const EmailNotVerified = () => {
  const { logout } = useAuth0();

  const logoutHandler = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#9E80E2",
      }}
    >
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "400px",
          padding: "1rem",
          margin: "1rem",
        }}
      >
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent="space-between"
        >
          <img
            src={Trravo}
            alt="trravo"
            style={{ width: "100%", height: "auto", marginBottom: "1rem" }}
          />

          <Typography
            sx={{
              marginTop: "2rem",
              marginBottom: "1rem",
              fontWeight: "bold",
              textAlign: "center",

            }}
          >
            We are happy you signed up with Trravo. Please login to your email
            to activate your account.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              onClick={logoutHandler}
              variant="contained"
              sx={{ marginBottom: "1rem" }}
            >
              Log Out
            </Button>
          </Box>
        </Stack>
      </Card>
    </div>
  );
};

export default EmailNotVerified;
