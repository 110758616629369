import { type FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  IconButton,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import PatternFormat from "react-number-format";
import Trravo from "../../../assets/trravo.png";
import NumericFormat from "react-number-format";

import {
  validateContact,
  validateFullName,
  validateName,
  validateZipCode,
  validateZipCodeError,
} from "../../../lib/util/common/utils";
import subscriptionService from "../../../services/subscription/subscription.service";
import type SubscribeRequest from "../../../services/subscription/dto/SubscribeRequest";
import Subscription from "../../../services/subscription/dto/Subscription";

interface SubscriptionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  setIsSubscribeModalOpen: () => void;
}

const CustomTextField = styled(MuiTextField)({
  "& .MuiFormHelperText-root": {
    width: 250,
  },
});

const NAME = "name";
const CONTACT_NUMBER = "contactNumber";
const ZIP_CODE = "zipCodes";

const SubscriptionDialog: FC<SubscriptionDialogProps> = ({
  isOpen,
  onClose,
  setIsSubscribeModalOpen,
}) => {
  const [subscriptionModalError, setSubscriptionModalError] =
    useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [contactNumber, setContactNumber] = useState<string>("");

  const [name, setName] = useState<string>("");

  const [zipCodes, setZipCode] = useState<string | null>("");
  const [contactError, setContactError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;

    switch (target.name) {
      case NAME:
        setName(target.value);
        break;
      case CONTACT_NUMBER:
        setContactNumber(target.value);
        break;
      case ZIP_CODE:
        setZipCode(target.value);
        break;

      default:
        break;
    }
  };

  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case NAME:
        setNameError(validateFullName(value));
        break;

      case CONTACT_NUMBER:
        setContactError(validateContact(value));
        break;
      case ZIP_CODE:
        setZipCodeError(validateZipCodeError(value));
        break;
      default:
        break;
    }
  };

  const isFormValid = (): boolean => {
    const fullNameErrors = validateFullName(name ?? null);
    setNameError(fullNameErrors);
    const contactErrors = validateContact(contactNumber ?? null);
    setContactError(contactErrors);
    const zipCodeErrors = validateZipCodeError(zipCodes ?? null);
    setZipCodeError(zipCodeErrors);

    return (
      contactErrors === null &&
      fullNameErrors === null &&
      zipCodeErrors === null
    );
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;
    const zipCodeArray = zipCodes !== null ? [Number(zipCodes)] : [];
    const job: SubscribeRequest = {
      name,
      contactNumber,
      zipCodes: zipCodeArray,
    };

    try {
      await subscriptionService.create(job);
      setSnackbarOpen(true);
      setTimeout(() => {
        setIsSubscribeModalOpen();
      }, 2000);
    } catch {
      console.error("unable to subscribe");
    }
  };

  return (
    <>
      <Dialog
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        id="subscription-dialog"
        open={isOpen}
        onClose={onClose}
      >
        <IconButton
          sx={{
            color: "gray",
            marginLeft: "auto",
          }}
          size="small"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={Trravo}
            alt="trravo"
            style={{
              width: "50%",
              height: "100px",
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              margin: "0 auto",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Typography
                id="modal-modal-title"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "0.5rem",
                }}
              >
                BE THE FIRST TO KNOW
              </Typography>
              <Typography
                id="modal-modal-title"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                As a subscriber, you will be the first to know about any new
                jobs posted in your neighborhood.
              </Typography>
            </div>
          </div>

          <TextField
            label="Enter Full Name"
            placeholder="Enter Full Name"
            name={NAME}
            value={name}
            onChange={onInputChange}
            onBlur={onInputFocusOut}
            error={nameError !== null}
            helperText={nameError}
            variant="standard"
            required
          />

          <PatternFormat
            customInput={TextField}
            format="+1 (###)###-####"
            id="contact-Number"
            label=" Enter Phone Number"
            placeholder="Enter Phone Number"
            required
            sx={{ mt: "0.3rem" }}
            variant="standard"
            name={CONTACT_NUMBER}
            value={contactNumber}
            onChange={onInputChange}
            onBlur={onInputFocusOut}
            error={contactError !== null}
            helperText={contactError}
          />

          <NumericFormat
            placeholder="Enter ZipCode"
            label="Enter ZipCode"
            customInput={TextField}
            variant="standard"
            name={ZIP_CODE}
            value={zipCodes}
            onChange={onInputChange}
            onBlur={onInputFocusOut}
            error={zipCodeError !== null}
            helperText={zipCodeError}
            sx={{ mt: "0.6rem" }}
            allowNegative={false}
            decimalScale={0}
            inputProps={{
              maxLength: 5,
            }}
            required
          />
          <Button
            sx={{
              color: "success",
              textTransform: "unset",
              marginTop: "16px",
              marginLeft: "auto",
            }}
            variant="outlined"
            size="medium"
            onClick={handleSubmit}
          >
            Subscribe
          </Button>
          {subscriptionModalError && (
            <Typography sx={{ color: "red" }}>
              * Please fill all the required fields in correct format.
            </Typography>
          )}
        </Box>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message="Subscription successful"
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="success"
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          Thank you for subscribing.
        </Alert>
      </Snackbar>
    </>
  );
};

export default SubscriptionDialog;
