import React, { useEffect, useState } from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Paper, Stack, Typography } from "@mui/material";
import type JobCardDTO from "../../services/job/dtos/jobcard.dto";
import { format, TDate } from "timeago.js";
import moment from "moment";
import { currentUser } from "../../redux/features/userSlice";
import AppUser from "../../services/auth/AppUser";
import { RoleId } from "../../lib/util/common/registration/utils";
import { useSelector } from "react-redux";
import { USER_ROLE_ID } from "../../lib/constants/localstorageitem";

interface JobCardProps {
  jobDetails: JobCardDTO;
  children?: React.ReactNode;
}

interface StatusElementProps {
  status: string; 
}

const cardStyles = {
  display: "flex",
  justifyContent: "space-between",
};

const JobCard = ({ jobDetails, children }: JobCardProps) => {
  const {
    startDate,
    title,
    endDate,
    description,
    address,
    createdAt,
    price,
    priceType,
    employer,
  } = jobDetails;
  const [expanded, setExpanded] = useState(false);

  const maxDescriptionLength = 220;

  const shouldShowReadMore =
    description && description.length > maxDescriptionLength;

  const truncateText = () => {
    setExpanded(!expanded);
  };
  const appUser = useSelector(currentUser) as unknown as AppUser;

  useEffect(()=>{
    if (appUser.user.currentRole ) {
      localStorage.setItem(USER_ROLE_ID, appUser.user.currentRole.toString());
    }
  },[])

  const storedRoleId = localStorage.getItem(USER_ROLE_ID);
  const currentRole = storedRoleId != null && parseInt(storedRoleId);

  const StatusElement = ({ status }: StatusElementProps) => (
    <Typography sx={{ fontWeight: "bold" }}>{status}</Typography>
  );

  const getStatusText = () => {
    if (
      currentRole === RoleId.STUDENT ||
      currentRole === RoleId.PARENT
    ) {
      return jobDetails.hasOwnProperty("status") ? "Applied:" : "Posted:";
    } else {
      return "Posted:";
    }
  };

  return (
    <Paper elevation={10} sx={{ margin: "2px", mt: "0.5rem" }}>
      <CardContent>
        <Stack direction="column" sx={cardStyles} spacing={2}>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontSize: "1.5rem" }}>{title}</Typography>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <StatusElement status={getStatusText()}/>
              <Typography>
                {format(createdAt ?? (startDate as TDate))}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" sx={cardStyles}>
            <Typography sx={{ fontWeight: "bold" }}>
              Posted By: {employer.firstName} {employer.lastName}
            </Typography>
          </Stack>
          <Stack direction="row" sx={cardStyles}>
            {price && priceType ? (
              <Typography sx={{ fontWeight: "bold" }}>
                Price: $
                {`${price} ${
                  priceType === "perHr"
                    ? "/Hr"
                    : priceType === "perJob"
                    ? "/Job"
                    : priceType
                }`}
              </Typography>
            ) : null}
          </Stack>

          <Stack
            direction="column"
            sx={{
              ...cardStyles,
              overflow: "hidden",
              textOverflow: "unset",
              width: "60vw",
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              sx={{
                textAlign: "left",
                overflow: "hidden",
                width: "100%",
                display: expanded ? "block" : "-webkit-box",
                WebkitLineClamp: expanded ? "unset" : 3,
                WebkitBoxOrient: "vertical",
                cursor: shouldShowReadMore ? "pointer" : "initial",
                "& p": {
                  margin: 0,
                },
              }}
              onClick={shouldShowReadMore ? truncateText : undefined}
            />
          </Stack>

          <Stack direction="row" spacing={0} alignItems="center">
            <Typography sx={{ fontWeight: "bold" }}>Location:</Typography>
            <Typography>
              {address?.city} {address?.state} {address?.zipCode}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center">
            <Typography sx={{ fontWeight: "bold" }}>Job Dates:</Typography>
            <Typography>
              {startDate != null && moment.utc(startDate).format("MMM-DD-YYYY")}{" "}
              to {endDate != null && moment.utc(endDate).format("MMM-DD-YYYY")}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>{children}</CardActions>
    </Paper>
  );
};

export default JobCard;
