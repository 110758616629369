import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { JobsList } from "./JobsList";
import {
  getAppliedJobs,
  setAppliedJobs,
  setJobs,
} from "../../../redux/features/jobSlice";
import { currentUser } from "../../../redux/features/userSlice";
import employerService from "../../../services/employer/employer.service";
import type JobDTO from "../../../services/job/dtos/job.dto";
import jobService from "../../../services/job/job.service";
import { AnalyticsContext } from "../../../context/analytics";
import type AppUser from "../../../services/auth/AppUser";
import { RoleId } from "../../../lib/util/common/registration/utils";
import teenagerService from "../../../services/teenager/teenager.service";
import parentService from "../../../services/parent/parent.service";
import { USER_ID } from "../../../lib/constants/localstorageitem";


const ManageJobsPage = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);

  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const user = useSelector(currentUser);
  const id = localStorage.getItem(USER_ID) as string;
  const currentRole = appUser.user.currentRole;

  const jobs = useSelector(getAppliedJobs);

  const updateJobs = async (job: JobDTO) => {
    setLoading(true);
    analytics.track("EDIT_JOB", {
      title: job.title,
      employeeId: id,
    });
    const results = await jobService.update(job);
    dispatch(setJobs(results));
    setLoading(false);
  };

  const deleteJob = async (job: JobDTO) => {
    setLoading(true);
    await jobService.delete(job);
    void getJobs();
    setLoading(false);
  };

  useEffect(() => {
    void getJobs();
  }, [user.id]);

  const getJobs = async () => {
    try {
      if (id !== null) {
        setLoading(true);
        let results: any = [];
        if (currentRole === RoleId.STUDENT) {
          results = await teenagerService.getJobs(id);
        } else if (currentRole === RoleId.PARENT) {
          results = await parentService.getJobs(id);
        } else if (currentRole === RoleId.EMPLOYER) {
          results = await employerService.getJob(id);
        }
        dispatch(setAppliedJobs(results));

        setLoading(false);
      }
    } catch {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const loadMore = async () => {
    if (!isLastPage) {
      const newPage = page + 1;
      setPage(newPage);

      let results: any = [];
      if (currentRole === RoleId.STUDENT) {
        results = await teenagerService.getJobs(id, newPage);
      } else if (currentRole === RoleId.PARENT) {
        results = await parentService.getJobs(id, newPage);
      } else if (currentRole === RoleId.EMPLOYER) {
        results = await employerService.getJob(id, newPage);
      }
      if (results.length === 0 && newPage > 1) {
        setIsLastPage(true);
      } else {
        dispatch(setAppliedJobs([...jobs, ...results]));
        if (results.length <= 10) {
          setIsLastPage(true);
        }
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <JobsList
        updateJobs={updateJobs}
        deleteJob={deleteJob}
        loadMore={loadMore}
      />
    </>
  );
};

export default ManageJobsPage;
