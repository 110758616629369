import type TeenProfileDTO from "./TeenProfile.dto";
import http from "../httpService";
import UserProfileDTO from "../UserProfile/UserProfile.dto";

class TeenProfileService {
  public async update(
    details: TeenProfileDTO,
    id: string
  ): Promise<TeenProfileDTO> {
    const result = await http.post(`teenager/update/${id}`, details);
    return result.data as TeenProfileDTO;
  }

  public async get(id: string): Promise<TeenProfileDTO> {
    const result = await http.get(`/user/profile2/${id}`);
    return result.data as TeenProfileDTO;
  }

 
}

export default new TeenProfileService();
