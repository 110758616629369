import UserProfileDTO from "../UserProfile/UserProfile.dto";
import http from "../httpService";
import type JobDTO from "../job/dtos/job.dto";

class ParentService {
  private readonly parentServiceUrl = `parent`;
  public async getJobs(id: string, page?: number): Promise<JobDTO> {
    const url = page
      ? `${this.parentServiceUrl}/${id}/jobs?page=${page}`
      : `${this.parentServiceUrl}/${id}/jobs`;
    const result = await http.get(url);
    return result.data as JobDTO;
  }

  // Parent Hybrid user Role
  public async ParenthybridUser(id: string): Promise<UserProfileDTO> {
    const result = await http.post(`hybrid/${this.parentServiceUrl}/${id}`);
    return result.data as UserProfileDTO;
  }
}

export default new ParentService();
