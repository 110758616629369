import { Auth0Provider } from "@auth0/auth0-react";
import AppConsts from "../../lib/constants/appConst";
import React from "react";

interface Auth0Props {
  children: React.ReactNode;
}
const Auth0ProviderWithHistory = ({ children }: Auth0Props) => {
  return (
    <Auth0Provider
      domain={AppConsts.auth0Domain}
      clientId={AppConsts.auth0ClientId}
      redirectUri={window.location.origin}
      audience={AppConsts.auth0Audience}
      scope={"profile email read:users"}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
