import { createSlice } from "@reduxjs/toolkit";
import { getDuration, sortByDuration } from "../../lib/util/common/utils";
import JobDTO from "../../services/job/dtos/job.dto";

interface InitialState {
  jobs: JobDTO[];
  manageJob: {
    job: JobDTO;
    applicants: [];
    appliedJobs: JobDTO[];
  };
}

const initialState: InitialState = {
  jobs: [],
  manageJob: {
    job: new JobDTO(),
    applicants: [],
    appliedJobs: [],
  },
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setJobs: (state, action) => {
      const activeJobs = action.payload.filter(
        (job: JobDTO) => job.isActive === true
      );
      activeJobs.map(
        (job: JobDTO) => (job.duration = getDuration(job.createdAt))
      );
      sortByDuration(activeJobs);
      return { ...state, jobs: activeJobs };
    },
    setAppliedJobs: (state, action) => {
      state.manageJob.appliedJobs = action.payload;
    },

    getManageJobs: (state, action) => {
      state.manageJob = action.payload;
    },
    setManageJobs: (state, action) => {
      state.manageJob.applicants = action.payload;
    },

    addJobsToList: (state, action) => {
      state.jobs = [...state.jobs, ...action.payload];
    },
    postJobs: (state, action) => {},
  },
});

export const getExistedJobs = (state: any) => state.job.manageJob;
export const getJobs = (state: any) => state.job.jobs;
export const getJobsId = (state: any) => state.job;
export const getAppliedJobs = (state: any) => state.job.manageJob.appliedJobs;
export const {
  addJobsToList,
  getManageJobs,
  postJobs,
  setAppliedJobs,
  setJobs,
  setManageJobs,
} = jobSlice.actions;
export default jobSlice.reducer;
