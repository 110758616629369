import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import type JobCardDTO from "../../services/job/dtos/jobcard.dto";
import JobCard from "./JobCard";
import DialogContent from "@mui/material/DialogContent";
import ApplicationRegistration from "./JobApplicationForm";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import GuestUser from "./GuestUser";
interface JobCardProps {
  jobDetails: JobCardDTO;
  onApply?: (id?: number) => void;
}

const ApplyJobCard = ({ jobDetails, onApply }: JobCardProps) => {
  const [open, setOpen] = useState(false);
  const [guestOpen, setGuestOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [apply, setApply] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const handleClose = () => {
    setGuestOpen(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setGuestOpen(true);
    setShow(true);
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message="Job Applied"
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity={apply ? "success" : "error"}
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Stack flexWrap={"wrap"}>
        <JobCard jobDetails={jobDetails}>
          <Stack>
            <Button onClick={handleClickOpen} variant="contained">
              Apply
            </Button>
            <Dialog open={guestOpen} onClose={handleClose}>
              {isAuthenticated ? (
                <>
                  <Stack alignItems="self-end">
                    <DisabledByDefaultIcon
                      onClick={handleClose}
                      sx={{ mt: "1.3rem", fontSize: "2rem" }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Job Application Form
                  </Typography>
                  <DialogContent>
                    <ApplicationRegistration
                      id={jobDetails.id}
                      name={jobDetails.title}
                      handleClose={handleClose}
                      setSnackbarOpen={setSnackbarOpen}
                      setApply={setApply}
                      setMessage={setMessage}
                    />
                  </DialogContent>
                </>
              ) : (
                <>
                  <Stack>
                    <Stack alignItems="self-end">
                      <DisabledByDefaultIcon
                        onClick={handleClose}
                        sx={{ fontSize: "2rem" }}
                      />
                    </Stack>
                    <GuestUser />
                  </Stack>
                </>
              )}
            </Dialog>
          </Stack>
        </JobCard>
      </Stack>
    </>
  );
};
export default ApplyJobCard;
