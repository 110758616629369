import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";

const AutoLogin = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    void loginWithRedirect();
  }, []);

  return <Typography>...Redirecting</Typography>;
};

export default AutoLogin;
