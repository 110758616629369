import { Stack, TextField } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import type AddressDTO from "../services/address/AddressDTO";

interface Props {
  edit?: boolean;
  getAddress: Function;
  placeHolder: string;
  searchKey?: string;
}

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

const Address = ({ getAddress, searchKey, placeHolder, edit }: Props) => {
  const [error, setError] = useState(false);
  const searchInput = useRef(null);
  const streetInput = useRef(null);
  const cityInput = useRef(null);
  const stateInput = useRef(null);
  const zipCodeInput = useRef(null);
  const countryInput = useRef(null);

  const [address, setAddress] = useState({});

  // const [value, setValue] = useState<string|undefined>();
  // load google map api js

  async function loadAsyncScript(src: string) {
    return await new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => {
        resolve(script);
      });
      document.head.appendChild(script);
    });
  }

  const extractAddress = (place: any) => {
    const address: AddressDTO = {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      plain() {
        const address1 = this.address1 ? this.address1 + ", " : "";
        const address2 = this.address2 ? this.address2 + ", " : "";

        const city = this.city ? this.city + ", " : "";
        const zipCode = this.zipCode ? this.zipCode + ", " : "";
        const state = this.state ? this.state + ", " : "";

        return city + zipCode + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component: any) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("street_number")) {
        address.address1 = value;
      } else if (types.includes("route")) {
        address.address1 = address.address1
          ? address.address1 + " " + value
          : value;
      }
      if (types.includes("subpremise")) {
        address.address2 = value;
      }

      if (
        Boolean(types.includes("locality")) ||
        Boolean(types.includes("neighborhood"))
      ) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zipCode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });
    // @ts-expect-error
    address.fullAddress = searchInput.current.value;

    return address;
  };
  useEffect(() => {
    getAddress(address);
  }, [address]);

  useEffect(() => {
    // @ts-expect-error
    searchInput.current.value = searchKey != null ? searchKey : "";
  }, [searchKey]);
  // init gmap script

  const initMapScript = async () => {
    // if script already loaded
    // @ts-expect-error
    if (window.google !== undefined) {
      await Promise.resolve();
      return;
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return await loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    // @ts-expect-error
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setComponentRestrictions({
      country: "us",
    });
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => {
      onChangeAddress(autocomplete);
    });
  };

  // @ts-expect-error

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    // @ts-expect-error

    searchInput.current.value = "Getting your location...";
    void fetch(url)
      .then(async (response) => await response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);
        // @ts-expect-error

        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => {
      initAutocomplete();
    });
  }, []);

  const streetChangeHandler = (e: any) => {
    // @ts-expect-error
    streetInput.current.value = e.target.value;
  };
  const cityChangeHandler = (e: any) => {
    // @ts-expect-error
    cityInput.current.value = e.target.value;
  };
  const stateChangeHandler = (e: any) => {
    // @ts-expect-error
    stateInput.current.value = e.target.value;
  };
  const zipChangeHandler = (e: any) => {
    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const value = e.target.value;
    if (value.length === 5) {
      setError(false);
      // @ts-expect-error
      zipCodeInput.current.value = e.target.value;
    } else {
      setError(true);
    }
  };
  const countryChangeHandler = (e: any) => {
    // @ts-expect-error
    countryInput.current.value = e.target.value;
  };
  const addressChangeHandler = () => {};

  return (
    <>
      <Stack direction="column" spacing={5} sx={{ mt: "6px", mb: 3 }}>
        <TextField
          required
          inputRef={searchInput}
          type="search"
          placeholder={placeHolder}
          label= "Enter Job location address"
          onChange={addressChangeHandler}
          disabled={edit}
        />
      </Stack>
    </>
  );
};

export default Address;
