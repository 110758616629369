import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const logoutHandler = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };

  return (
    <Button
      id="basic-button"
      onClick={logoutHandler}
      variant="outlined"
      sx={{
        border: "1px solid white",
        color: "white",
        background: "#9E80E2",
      }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
