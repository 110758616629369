import ApplicationDTO from "../ApplicationDTO/Application.DTO";
import UserProfileDTO from "../UserProfile/UserProfile.dto";
import http from "../httpService";
import type JobDTO from "../job/dtos/job.dto";
import JobseekerDTO from "../job/dtos/jobseeker.dto";
import type JobApplicationDTO from "../jobApplication/jobApplication.dtos";
import ProfileDTO from "../profile/profile.dto";
import type EmployerDTO from "./employer.dto";
import queryString from "query-string";

class EmployerService {
  employer(profile: (label?: string | undefined) => void) {
    throw new Error("Method not implemented.");
  }

  private readonly employerServiceURL = `employer`;
  private readonly jobseekerServiceURL = `jobseekers`
  public async create(
    vendors: EmployerDTO,
    auth0Id: string
  ): Promise<EmployerDTO> {
    const result = await http.post(`${this.employerServiceURL}`, {
      ...vendors,
      auth0Id,
    });
    return result.data as EmployerDTO;
  }

  // /// Get all employer information with filters
  // public async getAll(): Promise<EmployerDTO[]> {
  //     let result = await http.get('api/vendors/getAll');
  //     return result.data as EmployerDTO[];
  // }

  // //deactivate employer by ID
  public async deactivate(id: number): Promise<EmployerDTO> {
    const result = await http.patch(`${this.employerServiceURL}/delete/${id}`);
    return result.data as EmployerDTO;
  }

  public async getJob(id: string, page?:number): Promise<JobDTO> {
    const url= page ? `${this.employerServiceURL}/${id}/jobs?page=${page}`:`${this.employerServiceURL}/${id}/jobs`
    const result = await http.get(url);
    return result.data as JobDTO;
  }

  // // Update employer by ID
  // public async update(id: string): Promise<EmployerDTO> {
  //   const result = await http.patch(`${this.employerServiceURL}/${id}`);
  //   return result.data as EmployerDTO;
  // }

  public async ApproveJob(
    id: string,
    status: string,
    jobApp : ApplicationDTO
  ): Promise<JobApplicationDTO> {
    const result = await http.patch(
      `${this.employerServiceURL}/application/${id}`,
      {...jobApp,status }
    );
    return result.data as JobApplicationDTO;
  }

  public async updatePreference(
    id: number,
    employer: EmployerDTO
  ): Promise<EmployerDTO> {
    const result = await http.patch(`${this.employerServiceURL}/${id}`, {
      employer,
    });

    return result.data as EmployerDTO;
  }

  // //Get employer information by employer ID
  public async get(id: string): Promise<ProfileDTO> {
    const result = await http.get(`${this.employerServiceURL}/${id}`);
    return result.data as ProfileDTO;
  }

  public async getJobseeker(
    params : JobseekerDTO, skills?: string, page?:number
  ): Promise<ApplicationDTO[]> {
    let url = this.jobseekerServiceURL;
    if (page !== undefined) {
    url += `?page=${page}`;
    }
    if (skills) {
    url += `?skills=${skills}`;
    }
    const result = await http.get(url);
    return result.data as ApplicationDTO[];
  }

  // Employer Hybrid user Role 
  public async HybridUser(id: string): Promise<UserProfileDTO> {
    const result = await http.post(`hybrid/${this.employerServiceURL}/${id}`);
    return result.data as UserProfileDTO;
  }
}

export default new EmployerService();
