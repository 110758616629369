enum Roles {
  EMPLOYER = "Employer",
  PARENT = "Parent",
  STUDENT = "Student",
}

enum RoleId {
  EMPLOYER = 3,
  PARENT = 1,
  STUDENT = 4,
}

const RoleIdMapper: Record<Roles, number> = {
  [Roles.EMPLOYER]: RoleId.EMPLOYER,
  [Roles.PARENT]: RoleId.PARENT,
  [Roles.STUDENT]: RoleId.STUDENT,
};

export { RoleIdMapper, Roles, RoleId };
