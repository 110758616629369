import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAppUser, setAuth0User } from "../../redux/features/userSlice";
import Auth0User from "../../services/auth/Auth0User";
import authService from "../../services/auth/auth.service";
import { MANAGE_JOBS, PROFILE } from "../../lib/constants/navigationUrls";
import { RoleId } from "../../lib/util/common/registration/utils";

const Authorize = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      console.log("get token");
      if (isAuthenticated) {
        const idTokenClaims = await getIdTokenClaims();
        if (idTokenClaims != null) {
          const token = idTokenClaims.__raw;
          console.log("Token:", token);
          const auth0User = new Auth0User(user);
          const userResponse = await authService.authenticate(
            auth0User.auth0Id
          );
          dispatch(setAppUser(userResponse));
          dispatch(setAuth0User(auth0User));

          if (userResponse.user.currentRole === RoleId.EMPLOYER) {
            navigate(MANAGE_JOBS);
          } else {
            navigate(PROFILE);
          }
        }
        // Use the token as needed
      } else {
        // void loginWithRedirect();
      }
    };

    void getToken();
  }, [isAuthenticated]);
  return <Typography>Authorizing User</Typography>;
};

export default Authorize;
