import "./App.css";
import { useEffect, useState } from "react";
import JobFilter from "./JobFilter";
import jobService from "../../../services/job/job.service";
import { type JobSearchQueryDTO } from "../../../services/job/dtos/jobSearchQuery.dto";
import { Grid, Stack, Typography } from "@mui/material";
import {
  addJobsToList,
  getJobs,
  setJobs,
} from "../../../redux/features/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { JobList } from "./JobList";
import { useNavigate } from "react-router-dom";
import Footer from "../home/Footer";
import RegistrationHeader from "../RegistartionHeader";
import { useAuth0 } from "@auth0/auth0-react";
import TeenJobFilter from "./TeenJobFilter";

const TITLE = "title";
const ZIP_CODE = "zipCode";

export const JobSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [isLastPage, setIsLastPage] = useState<boolean>(false);

  const jobs = useSelector(getJobs);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryTitle = queryParams.get(TITLE) as string;
    const queryZip = queryParams.get(ZIP_CODE) as string;
    setTitle(queryTitle);
    setZip(queryZip);

    const data = {
      title: queryTitle,
      zipCode: queryZip,
    };

    void (async () => {
      void getJobsAPI(data);
    })();
  }, []);

  const onSearchHandle = (data: JobSearchQueryDTO) => {
    setZip(data.zipCode ?? "");
    setTitle(data.title ?? "");
    void getJobsAPI(data);
  };

  const getJobsAPI = async (data: JobSearchQueryDTO) => {
    const results = await jobService.search(data);
    dispatch(setJobs(results));
    if (results.length === 0) {
      setErrorMessage(
        `The search ${data.title} in ${data.zipCode}   did not match any jobs.`
      );
    } else {
      setErrorMessage(null);
    }
  };

  const loadMore = async () => {
    if (!isLastPage) {
      const newPage = page + 1;
      setPage(newPage);
      const results = await jobService.search({
        title,
        zipCode: zip,
        page: newPage,
      } satisfies JobSearchQueryDTO);
      dispatch(addJobsToList(results));
      if (results.length < 10) {
        setIsLastPage(true);
      }
    }
  };
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Grid direction="row">
        {isAuthenticated ? "" : <RegistrationHeader />}

        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ m: 5 }}
            direction="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <Typography
              sx={{ fontSize: 20, fontWeight: "bold", color: "black" }}
            >
              You can check search related jobs
            </Typography>

            {/* <Button
            sx={{ fontSize: 15, fontWeight: "bold" }}
            onClick={() => {
              navigate("/");
            }}
          >
            Home Page
          </Button> */}
          </Stack>
          <Stack sx={{ mt: "10rem" }}>
            {isAuthenticated ? <TeenJobFilter onSearch={onSearchHandle}/>  : <JobFilter title={title} zipCode={zip} onSearch={onSearchHandle} />}
          </Stack>
          <JobList
            jobs={jobs}
            loadMore={() => {
              void loadMore();
            }}
          />
          {errorMessage && (
            <div style={{ marginTop: "5rem" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "1.5rem",
                }}
              >
                {errorMessage}
              </Typography>
              <Stack direction={"column"} sx={{ alignItems: "center" }}>
                <Typography sx={{ fontWeight: "bold", mr: "9rem" }}>
                  Search Suggestions:
                </Typography>
                <ul style={{ fontSize: "1rem" }}>
                  <li>Try more general keywords</li>
                  <li>Try with similar words</li>
                  <li>Replace abbreviations with the entire word</li>
                </ul>
              </Stack>
            </div>
          )}
          {isAuthenticated ? (
            ""
          ) : (
            <div style={{ marginTop: "6rem" }}>
              <Footer />
            </div>
          )}
        </Stack>
      </Grid>
    </>
  );
};
