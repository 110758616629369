import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserProfileDTO from "../services/UserProfile/UserProfile.dto";
import {
  validateContact,
  validateEmail,
  validateFirstName,
  validateLastName,
} from "../lib/util/common/utils";
import { Divider, Typography } from "@material-ui/core";
import PatternFormat from "react-number-format";
import Dropzone from "./Dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  currentUser,
  getUserProfile,
  setUser,
} from "../redux/features/userSlice";
import AppUser from "../services/auth/AppUser";
import profileService from "../services/profile/profile.service";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { RoleId } from "../lib/util/common/registration/utils";
import employerService from "../services/employer/employer.service";
import { setEmployer } from "../redux/features/employerProfileSlice";
import { USER_ID } from "../lib/constants/localstorageitem";

type UserProps = {
  userData: UserProfileDTO;
  onSave: (userData: UserProfileDTO) => void;
};

const UserProfileCard = ({ userData, onSave }: UserProps) => {
  const [userProfileData, setUserProfileData] = useState(userData);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [edit, setEdit] = useState(true);
  const [imagesPreviewURL, setImagesPreviewURL] = useState<string | null>(null);
  const [imagesError, setImagesError] = useState<string | null>(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [open, setOpen] = React.useState(false);
  const [disable, setDisabled] = useState(false);
  const { firstName, lastName, email, contactNumber } = userProfileData;
  const [editedValues, setEditedValues] = useState<UserProfileDTO>({
    firstName: userProfileData.firstName,
    lastName: userProfileData.lastName,
    email: userProfileData.email,
    contactNumber: userProfileData.contactNumber,
    profileImage: userProfileData.profileImage,
  });

  const FIRSTNAME = "firstName";
  const LASTNAME = "lastName";
  const EMAIL = "email";
  const PHONENUMBER = "phonenumber";
  const PROFILEIMAGE = "profileImage";

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;

    const updatedValues: UserProfileDTO = { ...editedValues };
    switch (target.name) {
      case FIRSTNAME:
        updatedValues.firstName = target.value;
        break;
      case LASTNAME:
        updatedValues.lastName = target.value;
        break;
      case EMAIL:
        updatedValues.email = target.value;
        break;
      case PHONENUMBER:
        updatedValues.contactNumber = target.value;
        break;
      case PROFILEIMAGE:
        updatedValues.profileImage = target.files?.[0] || null;

        if (target.files?.[0]) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagesPreviewURL(reader.result as string);
          };
          reader.readAsDataURL(target.files[0]);
        } else {
          setImagesPreviewURL(null);
        }
        break;
      default:
        break;
    }
    setEditedValues(updatedValues);
  };

  const onInputFocusOut = (event: any): void => {
    const { target } = event;
    const { name, value } = target;

    switch (name) {
      case FIRSTNAME:
        setFirstNameError(validateFirstName(value));
        break;

      case LASTNAME:
        setLastNameError(validateLastName(value));
        break;

      case EMAIL:
        setEmailError(validateEmail(value));
        break;

      case PHONENUMBER:
        setPhoneNumberError(validateContact(value));
        break;

      default:
        break;
    }
  };

  const isFormValid = (userData: UserProfileDTO): boolean => {
    const fNameError = validateFirstName(firstName);
    setFirstNameError(fNameError);

    const lNameError = validateLastName(lastName);
    setLastNameError(lNameError);

    const phnNoError = validateContact(contactNumber);
    setPhoneNumberError(phnNoError);

    return fNameError === null && lNameError === null && phnNoError === null;
  };

  const onSaveClick = (userData: UserProfileDTO): void => {
    if (isFormValid(userData)) {
      onSave(editedValues);
      setOpen(true);
      setDisabled(false);
      setUserProfileData(editedValues);
    }
    setEdit(true);
  };

  const onEdit = () => {
    setEdit(false);
    setEditedValues({
      firstName: userProfileData.firstName,
      lastName: userProfileData.lastName,
      email: userProfileData.email,
      contactNumber: userProfileData.contactNumber,
      profileImage: userProfileData.profileImage,
    });
    setFirstNameError(null);
    setLastNameError(null);
    setPhoneNumberError(null);
  };

  const handleCancelClick = () => {
    setEditedValues({
      firstName: userProfileData.firstName,
      lastName: userProfileData.lastName,
      email: userProfileData.email,
      contactNumber: userProfileData.contactNumber,
      profileImage: userProfileData.profileImage,
    });
    setEdit(true);
  };

  const onDrop = (acceptedFiles: any) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxFileSize = 2 * 1024 * 1024;

    const validFiles = acceptedFiles.filter(
      (file: any) =>
        allowedTypes.includes(file.type) && file.size <= maxFileSize
    );

    const oversizedFiles = acceptedFiles.filter(
      (file: any) => file.size > maxFileSize
    );
    const invalidTypeFiles = acceptedFiles.filter(
      (file: any) => !allowedTypes.includes(file.type)
    );

    if (invalidTypeFiles.length > 0 && oversizedFiles.length > 0) {
      setImagesError(
        "Please select only JPEG, JPG, or PNG images that are up to 2 MB in size."
      );
    } else if (invalidTypeFiles.length > 0) {
      setImagesError("Please select only JPEG, JPG, or PNG images.");
    } else if (oversizedFiles.length > 0) {
      setImagesError("Each image should be up to 2 MB in size.");
    } else {
      setSelectedImages(validFiles);
      setImagesError("");

      const data: UserProfileDTO = {
        ...userProfileData,
        profileImage: validFiles,
      };
      setUserProfileData(data);
    }
  };

  const appUser = useSelector(currentUser) as unknown as AppUser;
  const dispatch = useDispatch();
  const userId = localStorage.getItem(USER_ID) as string;
  useEffect(() => {
    void (async () => {
      if (appUser.user.currentRole === RoleId.EMPLOYER) {
        const res = await employerService.get(userId);
        dispatch(setUser(res));
        setUserProfileData((prevData: any) => ({
          ...prevData,
          firstName: res?.firstName || "",
          lastName: res?.lastName,
          email: res?.email || "",
          contactNumber: res?.contactNumber || "",
        }));
      } else {
        const res = await profileService.getProfileData(userId);
        dispatch(setUser(res));
        setUserProfileData((prevData: any) => ({
          ...prevData,
          firstName: res?.firstName || "",
          lastName: res?.lastName,
          email: res?.email || "",
          contactNumber: res?.contactNumber || "",
        }));
      }
    })();
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const disableEmpty = (): boolean => {
    return (
      phoneNumberError != null ||
      firstNameError != null ||
      lastNameError != null
    );
  };

  return (
    <>
      <Stack direction={"column"} display={"flex"} minHeight={"550px"}>
        <Box
          sx={{
            width: 500,
            height: 500,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <Typography style={{ fontSize: "1.5rem", fontFamily: "Impact" }}>
              My Profile
            </Typography>
          </div>
          <Grid container height={"75%"}>
            <Grid item xs={8}>
              <Stack
                height={"100%"}
                display={"flex"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
              >
                {edit ? (
                  <Stack direction={"row"} alignItems="center" sx={{ width: "230px" }}>
                    <PersonIcon
                      style={{ marginTop: "0.3rem", fontSize: "2.5rem" }}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        style={{ fontWeight: "bold", marginRight: "9rem" }}
                      >
                        Name
                      </Typography>
                      <div style={{ maxWidth: "100%" }}>
                        <Tooltip title={`${firstName} ${lastName}`} arrow>
                          <Typography > {`${firstName} ${lastName}`.length > 25
                                        ? `${firstName} ${lastName}`.slice(0, 25) + "..."
                                        : `${firstName} ${lastName}`}</Typography>
                        </Tooltip>
                        </div>
                      <Divider />
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="FirstName"
                      placeholder="Enter First Name"
                      variant="outlined"
                      name={FIRSTNAME}
                      value={editedValues.firstName}
                      error={firstNameError !== null}
                      helperText={firstNameError}
                      onChange={onInputChange}
                      onBlur={onInputFocusOut}
                      disabled={edit}
                      required
                    />
                    <TextField
                      id="outlined-basic"
                      label="LastName"
                      placeholder="Enter Last Name"
                      variant="outlined"
                      name={LASTNAME}
                      value={editedValues.lastName}
                      error={lastNameError !== null}
                      helperText={lastNameError}
                      onChange={onInputChange}
                      onBlur={onInputFocusOut}
                      disabled={edit}
                      required
                    />
                  </>
                )}

                {edit ? (
                    <Stack direction={"row"} alignItems="center" sx={{ width: "230px" }}>
                      <EmailIcon
                        style={{ marginTop: "0.3rem", fontSize: "2.5rem" }}
                      />
                      <Stack direction={"column"}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Email
                        </Typography>
                        <div style={{ maxWidth: "100%" }}>
                        <Tooltip title={email} arrow>
                          <Typography >{email.length > 28 ? email.slice(0, 28) + "..." : email}</Typography>
                        </Tooltip>
                        </div>
                        <Divider />
                      </Stack>
                    </Stack>
                ) : (
                  <TextField
                    id="outlined-basic"
                    label="Email "
                    variant="outlined"
                    name={EMAIL}
                    value={editedValues.email}
                    error={emailError !== null}
                    helperText={emailError}
                    onChange={onInputChange}
                    onBlur={onInputFocusOut}
                    disabled={true}
                    required
                  />
                )}

                {edit ? (
                  <Stack direction={"row"}>
                    <PhoneIcon
                      style={{ marginTop: "0.3rem", fontSize: "2.5rem" }}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        style={{ fontWeight: "bold", marginRight: "4.5rem" }}
                      >
                        Phone Number
                      </Typography>
                      <Typography>{contactNumber}</Typography>
                      <Divider />
                    </Stack>
                  </Stack>
                ) : (
                  <PatternFormat
                    placeholder="Enter Phone Number"
                    id="outlined-basic"
                    customInput={TextField}
                    label="Phone Number"
                    variant="outlined"
                    format="+1 (###)###-####"
                    name={PHONENUMBER}
                    value={editedValues.contactNumber}
                    onChange={onInputChange}
                    onBlur={onInputFocusOut}
                    error={phoneNumberError !== null}
                    helperText={phoneNumberError}
                    disabled={edit}
                    required
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Grid item xs={4}>
                <Stack
                  direction={"column"}
                  sx={{
                    width: "7rem",
                    height: "7rem",
                    backgroundColor: "#9E80E2",
                    borderRadius: "50%",
                  }}
                >
                  <Dropzone
                    onDrop={onDrop}
                    selected={selectedImages}
                    errors={imagesError}
                    type={PROFILEIMAGE}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {edit ? (
              <Stack
                style={{
                  height: "15%",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "320px",
                }}
              >
                <Button variant="contained" onClick={onEdit}>
                  Edit
                </Button>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Button variant="contained" onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onSaveClick(userProfileData);
                  }}
                  disabled={disable || disableEmpty()}
                >
                  Save
                </Button>
              </Stack>
            )}
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                Profile Updated Succesfully
              </Alert>
            </Snackbar>
          </div>
        </Box>
      </Stack>
    </>
  );
};

export default UserProfileCard;
