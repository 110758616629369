class Auth0User {
  email_verified: boolean;
  email: string;
  name: string;
  nickname: string;
  picture: string;
  sid: string;
  sub: string;
  updated_at: string;
  __raw: string;
  userInfo: {
    type: string;
  };

  auth0Id: string;

  constructor(data: Partial<Auth0User> = {}) {
    this.email = data.email ?? "";
    this.email_verified = data.email_verified ?? false;
    this.name = data.name ?? "";
    this.nickname = data.nickname ?? "";
    this.picture = data.picture ?? "";
    this.sid = data.sid ?? "";
    this.sub = data.sub ?? "";
    this.updated_at = data.updated_at ?? "";
    this.auth0Id = this.setAuth0Id();
    this.__raw = data.__raw ?? "";
    this.userInfo = data.userInfo ?? {
      type: "",
    };
  }

  private readonly setAuth0Id = (): string => {
    if (this.sub.length > 0) {
      return this.sub.split("|")[1];
    } else {
      return "";
    }
  };

  public getToken = (): string => {
    return `Bearer ${this.__raw}`;
  };
}

export default Auth0User;
