class AddressDTO {
  fullAddress?: string;
  address1?: string;
  address2?: string;
  street?: string;
  route?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  plain?: Function;

  constructor(data: any = {}) {
    this.fullAddress = data.fullAddress || null;
    this.address1 = data.address1 || null;
    this.address2 = data.address2 || null;
    this.street = data.street || null;
    this.route = data.route || "";
    this.city = data.city || "";
    this.state = data.state || "";
    this.zipCode = data.zipCode || "";
    this.country = data.country || "";
    this.plain = data.plain || "";
  }
}

export default AddressDTO;
