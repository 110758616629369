import ProfileDTO from "../profile/profile.dto";
import Auth0User from "./Auth0User";
import User from "./User";

class AppUser {
  auth0User: Auth0User;
  authProfile: any;
  authState: any;
  id: number | null;
  permissions: string[];
  role: string;
  user: User;
  userProfile: ProfileDTO;

  constructor(_data: Partial<AppUser> = {}) {
    this.auth0User = new Auth0User(_data.auth0User);
    this.id = _data.id ?? null;
    this.permissions = _data.permissions ?? [];
    this.role = _data.role ?? "";
    this.user = new User(_data.user) ?? new User();
    this.userProfile = new ProfileDTO();
  }
}

export default AppUser;
