import { createSlice } from "@reduxjs/toolkit";
import AppUser from "../../services/auth/AppUser";
import Auth0User from "../../services/auth/Auth0User";
import ProfileDTO from "../../services/profile/profile.dto";

interface UserState {
  userProfile: ProfileDTO;
  user: AppUser;
  auth0User: Auth0User;
}

const initialState: UserState = {
  userProfile: new ProfileDTO(),
  user: new AppUser(),
  auth0User: new Auth0User(),
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userProfile = action.payload;
    },
    setAuth0User: (state, action) => {
      state.auth0User = action.payload;
    },
    setAppUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const getUserProfile = (state: UserState) => state.user;
export const auth0User = (state: UserState) => state.user.auth0User;
export const currentUser = (state: UserState) => state.user.user;
export const { setUser, setAuth0User, setAppUser } = userSlice.actions;
export default userSlice.reducer;
