import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import "./Home.css";
import { type FC, useEffect, useState } from "react";
import LoginButton from "../../../components/Auth/Login";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { auth0User } from "../../../redux/features/userSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { MANAGE_JOBS, SIGN_UP } from "../../../lib/constants/navigationUrls";
import MenuIcon from "@mui/icons-material/Menu";

const SIGN_UP_LABEL = "Register";

function HomePage() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isAuthenticated } = useAuth0();
  const currentAuth0User = useSelector(auth0User);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //  && navigate('vendor/jobs')
    if (isAuthenticated) {
      if (currentAuth0User.userInfo.type === "employer") {
        navigate(MANAGE_JOBS);
      }
    }
  }, [isAuthenticated]);

  const HomePageCTA: FC<{ label: string }> = ({ label }) => {
    return (
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={() => {
          navigate(SIGN_UP);
        }}
        variant="outlined"
        sx={{
          border: "1px solid white",
          color: "white",
          background: "#9E80E2",
          height: "2.4rem",
        }}
      >
        {label}
      </Button>
    );
  };
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth > 300 &&
      window.innerWidth < 750 &&
      window.innerHeight < 1000
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(
        window.innerWidth > 300 &&
          window.innerWidth < 750 &&
          window.innerHeight < 1000
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMiniScreen, setIsMiniScreen] = useState(
    window.innerWidth < 300 && window.innerHeight < 1000
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMiniScreen(window.innerWidth < 300 && window.innerHeight < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth > 751 &&
      window.innerWidth < 950 &&
      (window.innerHeight < 1200 || window.innerHeight < 1400)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(
        window.innerWidth > 751 &&
          window.innerWidth < 950 &&
          (window.innerHeight < 1200 || window.innerHeight < 1400)
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      style={{
        backgroundColor: "#9E80E2",
        height: "100%",
      }}
    >
      <div
        style={{
          marginLeft: isMiniScreen || isSmallScreen ? "0" : "2rem",
          position: "relative",
        }}
      >
        <span
          className="style"
          style={{ height: isMiniScreen ? "350px" : "400px" }}
        >
          <img src="trravo.png" className="image"></img>
        </span>
      </div>

      {isSmallScreen || isMiniScreen || isMediumScreen ? (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton
            id="menu-button"
            aria-controls="menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleMenuClick}
          >
            <MenuIcon style={{ color: "black", fontSize: "3rem" }} />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <HomePageCTA label={SIGN_UP_LABEL} />
            </MenuItem>
            <MenuItem>
              {" "}
              <LoginButton />
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <HomePageCTA label={SIGN_UP_LABEL} />
          <Button onClick={handleClose}>
            <LoginButton />
          </Button>
        </Box>
      )}

      <Grid
        container
        sx={{
          px: isSmallScreen ? "10px" : "30px",
          width: "100%",
          marginBottom: isSmallScreen ? "20px" : "0",
        }}
        textAlign={"center"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: isMediumScreen
              ? "200px"
              : isMiniScreen
              ? "130px"
              : isSmallScreen
              ? "225px"
              : "300px",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={isMediumScreen ? 12 : 6}
          sx={{
            height: isMediumScreen
              ? "240px"
              : isMiniScreen
              ? "145px"
              : isSmallScreen
              ? "215px"
              : "100%",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: isMediumScreen
                ? "40px"
                : isMiniScreen
                ? "16px"
                : "30px",
              paddingLeft: "13px",
              paddingRight: "13px",
            }}
          >
            Welcome to Trravo! Our platform connects teenagers with freelance
            job opportunities!
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default HomePage;
