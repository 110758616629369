import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MANAGE_JOBS } from "../../../lib/constants/navigationUrls";
import JobDTO from "../../../services/job/dtos/job.dto";
import jobService from "../../../services/job/job.service";
import NewJobForm from "../newJob/NewJobForm";
import { useSelector } from "react-redux";
import { currentUser } from "../../../redux/features/userSlice";
import AppUser from "../../../services/auth/AppUser";

interface IProps {
  edit?: boolean;
  job?: JobDTO;
  updateJobs?: Function;
  view?: boolean;
}

export const JobPost = ({ edit, job, updateJobs, view }: IProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const id = appUser.user.id?.toString() ?? "";
  const onJobApply = async (job: JobDTO) => {
    const result = await jobService.create(id,job);
    setSnackbarOpen(true);
    setTimeout(() => {
      navigate(MANAGE_JOBS);
    }, 1000);
  };

  const afterClick = () => {
    setSnackbarOpen(false);
    navigate(MANAGE_JOBS);
  };

  return (
    <div>
      <NewJobForm
        onApply={onJobApply}
        job={new JobDTO({ startDate: new Date(), endDate: new Date() })}
      />
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message="Job Created"
      >
        <Alert onClose={afterClick} severity="success" sx={{ width: "100%" }}>
          Job created successfully.
        </Alert>
      </Snackbar>
    </div>
  );
};
