export const ABOUT = "/about";
export const CHILD_INFO = "/child";
export const EMAIL_NOT_VERIFIED = "/emailNotVerified";
export const HOME = "/";
export const JOB_POST = "/employer/jobpost";
export const LOGIN = "/login";
export const MANAGE_JOBS = "/employer/jobs";
export const PROFILE = "/profile";
export const SEARCH = "/search";
export const SIGN_UP = "/sign-up";
export const TEEN_PROFILE = "/teen";
export const EMPLOYER_PROFILE = "/employer/employer-profile";
export const EMPLOYER_PARENT= "/employer/employer-parent";
export const JOBSEEKER_SEARCH = "/employer/jobseeker-search";
export const JOB_SEARCH = "/teen/job-search";
export const TEEN_APPLICATIONS = "/teen/applications";
export const TEEN_PROFILE_PAGE = "/teen/profile";
export const TEEN_APPLIED_JOBS = "/parent/applications";
export const PARENT_EMPLOYER = "/parent/parent-employer";
export const PARENT_PROFILE = "/parent/profile";
