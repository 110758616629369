import { Button, CircularProgress, IconButton, Stack } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  validateContact,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePwd,
} from "../../lib/util/common/utils";
import PatternFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LOGIN } from "../../lib/constants/navigationUrls";

const CONFIRM_PASSWORD = "confirm-password";
const CONTACT_NUMBER = "contact-number";
const EMAIL = "email";
const FIRST_NAME = "first-name";
const LAST_NAME = "last-name";
const PASSWORD = "password";

interface RegistrationFormProps {
  contactNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  loading: boolean;
  onSubmit: (
    firstName: string,
    lastName: string,
    email: string,
    contactNumber: string,
    password: string
  ) => void;
  password?: string;
  selectedRoleId: number;
}

const TextField = styled(MuiTextField)({
  "& .MuiFormHelperText-root": {
    width: 250,
  },
});

export const Registration = ({
  contactNumber: existingContactNumber,
  email: existingEmail,
  firstName: existingFirstName,
  lastName: existingLastName,
  loading,
  onSubmit,
  password: existingPassword,
  selectedRoleId,
}: RegistrationFormProps) => {
  const navigate = useNavigate();

  const [confirmPassword, setConfirmPassword] = useState(
    existingPassword ?? ""
  );
  const [contactNumber, setContactNumber] = useState<string>(
    existingContactNumber ?? ""
  );
  const [email, setEmail] = useState<string>(existingEmail ?? "");
  const [firstName, setFirstName] = useState<string>(existingFirstName ?? "");
  const [lastName, setLastName] = useState<string>(existingLastName ?? "");
  const [password, setPassword] = useState<string>(existingFirstName ?? "");
  // Errors
  const [errorFirstName, setErrorFirstName] = useState<string | null>(null);
  const [errorLastName, setErrorLastName] = useState<string | null>(null);
  const [passwordErrors, setPasswordErrors] = useState<Array<string | null>>(
    []
  );
  const [errorConfirmPasswords, setErrorConfirmPasswords] = useState<
    Array<string | null>
  >([]);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorContact, setErrorContact] = useState<string | null>(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  useEffect(()=>{
      setErrorFirstName(null);
      setErrorLastName(null);
      setErrorEmail(null);
      setErrorContact(null);
      setPasswordErrors([]);
      setErrorConfirmPasswords([]);
  },[selectedRoleId]);

  const onSubmitClick = (): void => {
    if (isFormValid()) {
      onSubmit(firstName, lastName, email, contactNumber, password);
    }
  };

  const isFormValid = (): boolean => {
    const confirmPasswords = confirmPassword;

    const firstNameErrors = validateFirstName(firstName ?? null);
    setErrorFirstName(firstNameErrors);
    const lastNameErrors = validateLastName(lastName ?? null);
    setErrorLastName(lastNameErrors);
    const emailError = validateEmail(email ?? null);
    setErrorEmail(emailError);
    const contactErrors = validateContact(contactNumber ?? null);
    setErrorContact(contactErrors);
    const passwordErrors = validatePassword(password ?? null);
    setPasswordErrors([passwordErrors]);
    const confirmPasswordError = validateConfirmPassword(confirmPasswords);
    setErrorConfirmPasswords([confirmPasswordError]);

    return (
      contactErrors === null &&
      emailError === null &&
      passwordErrors === null &&
      confirmPasswordError === null &&
      firstNameErrors === null &&
      lastNameErrors === null
    );
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;

    switch (target.name) {
      case FIRST_NAME:
        setFirstName(target.value);
        break;
      case LAST_NAME:
        setLastName(target.value);
        break;
      case EMAIL:
        setEmail(target.value);
        break;
      case CONTACT_NUMBER:
        setContactNumber(target.value);
        break;
      case PASSWORD:
        setPassword(target.value);
        break;
      default:
        break;
    }
  };

  const onConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case FIRST_NAME:
        setErrorFirstName(validateFirstName(value));
        break;
      case LAST_NAME:
        setErrorLastName(validateLastName(value));
        break;
      case EMAIL:
        setErrorEmail(validateEmail(value));
        break;
      case CONTACT_NUMBER:
        setErrorContact(validateContact(value));
        break;
      case PASSWORD:
        setPasswordErrors([validatePassword(value)]);
        break;
      case CONFIRM_PASSWORD:
        setErrorConfirmPasswords([validateConfirmPassword(value)]);
        break;
      default:
        break;
    }
  };

  const validateConfirmPassword = (value: string | null): string | null => {
    let message = null;
    if (value === "") {
      message = "Password is Required";
    } else if (value !== password) {
      message = "Password must match with confirm password";
    }
    return message;
  };

  const validatePassword = (value: string | null): string | null => {
    let message = null;
    if (value === "") {
      message = "Password is Required";
    } else if (validatePwd(password) == null) {
      message = 
        "Password must contain at least 7 characters, including one Upper case ,Lower case & Number and Special Character";
    }
    return message;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword= () =>{
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <Box sx={{height:"25rem", width: "97%", display:"flex", flexDirection:"column" , justifyContent:"space-between"}} flexWrap="wrap">

      {loading && <CircularProgress color="inherit" />}

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        spacing={3}
        width={"100%"}
      >
        <TextField
          error={errorFirstName !== null}
          required
          type="text"
          id="FName"
          placeholder="First Name"
          label="First Name"
          name={FIRST_NAME}
          value={firstName}
          onChange={onInputChange}
          onBlur={onInputFocusOut}
          helperText={errorFirstName}
          fullWidth
          variant="standard"
          sx={{ width: "50%" }}
        />
        <TextField
          required
          error={errorLastName !== null}
          helperText={errorLastName}
          type="text"
          autoComplete="none"
          id="LName"
          placeholder="Last Name"
          name={LAST_NAME}
          label="Last Name"
          onBlur={onInputFocusOut}
          value={lastName}
          onChange={onInputChange}
          fullWidth
          variant="standard"
          sx={{ width: "50%" }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        width={"100%"}
      >
        <TextField
          required
          error={errorEmail !== null}
          type={"email"}
          placeholder="Email ID"
          id="Email-ID"
          label="Email"
          name="email"
          autoComplete="none"
          value={email}
          className="textFeildSize"
          onChange={onInputChange}
          onBlur={onInputFocusOut}
          helperText={errorEmail}
          // sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
        />
      </Stack>
      <Stack width={"100%"}>
        <PatternFormat
          required
          placeholder="Enter Phone Number"
          id="contact-Number"
          customInput={TextField}
          label="Phone Number"
          variant="standard"
          format="+1 (###)###-####"
          name={CONTACT_NUMBER}
          value={contactNumber}
          onChange={onInputChange}
          onBlur={onInputFocusOut}
          error={errorContact !== null}
          helperText={errorContact}
          fullWidth
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        spacing={3}
        width={"100%"}
      >
        <TextField
          required
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          id="Password"
          name={PASSWORD}
          label="Password"
          value={password}
          sx={{ width: "50%" }}
          className="textFeildSize"
          onChange={onInputChange}
          onBlur={onInputFocusOut}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            ),
          }}
          error={passwordErrors.length > 0 && passwordErrors[0] !== null}
          helperText={
            <>
              {[passwordErrors].map((error) => {
                return <div key={`${error.toString()}`}>{error}</div>;
              })}
            </>
          }
          fullWidth
          variant="standard"
        />

        <TextField
          required
          type={showConfirmPassword? "text" : "password"}
          placeholder="Confirm Password"
          name={CONFIRM_PASSWORD}
          label="Confirm Password"
          value={confirmPassword}
          onChange={onConfirmPasswordChange}
          onBlur={onInputFocusOut}
          sx={{ width: "50%" }}
          fullWidth
          variant="standard"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleConfirmPassword}>
                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            ),
          }}
          error={
            errorConfirmPasswords.length > 0 &&
            errorConfirmPasswords[0] !== null
          }
          helperText={
            <>
              {[errorConfirmPasswords].map((i) => {
                return <div key={i.toString()}>{i}</div>;
              })}
            </>
          }
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        gap={"1rem"}
        width={"100%"}
        sx={{ marginBottom: "1rem" }}
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate(LOGIN);
          }}
        >
          Already user
        </Button>
        <Button variant="contained" onClick={onSubmitClick}>
          Register
        </Button>
      </Stack>
    </Box>
  );
};
