import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getExistedJobs } from "../redux/features/jobSlice";

type Props = {
  getDescription: Function;
  edit?: boolean;
  description?: string;
};

const TextEditor = ({ getDescription, edit, description }: Props) => {
  const [value, setValue] = useState<string | undefined>();
  const teen = useSelector(getExistedJobs);
  useEffect(() => {
    getDescription(value);
  }, [value]);
  useEffect(() => {
    edit && setValue(description);
  }, [edit]);
  useEffect(() => {
    edit && setValue(teen.job.description);
  }, [teen]);
  return (
    <>
      <ReactQuill
        theme="snow"
        readOnly={edit}
        value={value}
        onChange={setValue}
        placeholder="Job Description along with Working Hours & Payment Terms"
        style={{ height: "14rem", marginBottom: "3vw", marginTop: "1vw" }}
      />
    </>
  );
};

export default TextEditor;
