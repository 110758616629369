import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, {
  type AppBarProps as MuiAppBarProps,
} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link, Outlet, useNavigate } from "react-router-dom";
import LogoutButton from "../Auth/Logout";
import { Button, Dialog, Slide, Stack, Switch } from "@mui/material";
import {
  currentUser,
  getUserProfile,
  setAppUser,
  setAuth0User,
  setUser,
} from "../../redux/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import employerService from "../../services/employer/employer.service";
import {
  currentEmployer,
  setEmployer,
} from "../../redux/features/employerProfileSlice";
import Auth0User from "../../services/auth/Auth0User";
import authService from "../../services/auth/auth.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  EMAIL_NOT_VERIFIED,
  EMPLOYER_PROFILE,
  JOB_SEARCH,
  MANAGE_JOBS,
  PARENT_PROFILE,
  PROFILE,
  TEEN_APPLICATIONS,
  TEEN_APPLIED_JOBS,
  TEEN_PROFILE_PAGE,
  JOBSEEKER_SEARCH,
  EMPLOYER_PARENT,
  PARENT_EMPLOYER,
} from "../../lib/constants/navigationUrls";
import { RoleId } from "../../lib/util/common/registration/utils";
import AppUser from "../../services/auth/AppUser";
import profileService from "../../services/profile/profile.service";
import { ROLE_ID, USER_ID } from "../../lib/constants/localstorageitem";
import App from "../../App";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open === true && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open === true && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface MenuItem {
  displayName: string;
  route: string;
}

interface MenuProps {
  menu: MenuItem[];
}
const LayOut = ({ menu }: MenuProps) => {
  return (
    <>
      <List>
        {menu.map((item: MenuItem, index: number) => (
          <Link
            key={item.displayName}
            to={item.route}
            style={{ textDecoration: "none", color: "gray" }}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={item.displayName} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </>
  );
};

export const AppLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employerProfileData = useSelector(currentEmployer);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const appUser = useSelector(currentUser) as unknown as AppUser;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [call, setCall] = React.useState(false);

  const onNavOpen = () => {
    setCall(true);
  };

  const onNavClose = () => {
    setCall(false);
  };

  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        if (user?.email_verified !== true) {
          navigate(EMAIL_NOT_VERIFIED);
        } else {
          const idTokenClaims = await getIdTokenClaims();
          if (idTokenClaims != null) {
            const token = idTokenClaims.__raw;
            console.log("Token:", token, user);
            const auth0User = new Auth0User(user);
            const userResponse = await authService.authenticate(
              auth0User.auth0Id
            );
            dispatch(setAppUser(userResponse));
            dispatch(setAuth0User(auth0User));
            if (userResponse.user.roles > 1) {
              return null;
            } else if (userResponse.user.roles === 1)
              if (userResponse.user.currentRole === RoleId.EMPLOYER) {
                navigate(MANAGE_JOBS);
              } else if (userResponse.user.currentRole === RoleId.STUDENT) {
                navigate(JOB_SEARCH);
              } else if (userResponse.user.currentRole === RoleId.PARENT) {
                navigate(TEEN_APPLIED_JOBS);
              } else {
                navigate(PROFILE);
              }
          }
        }
        // Use the token as needed
      }
    };

    void getToken();
  }, [isAuthenticated]);

  const [employerMenu, setEmployerMenu] = React.useState([
    {
      displayName: "Manage Jobs",
      route: MANAGE_JOBS,
    },

    {
      displayName: "Employer Profile",
      route: EMPLOYER_PROFILE,
    },
    // {
    //   displayName: "Jobseeker Search",
    //   route: JOBSEEKER_SEARCH,
    // },
  ]);

  const checking = async () => {
    const userId = localStorage.getItem(USER_ID);
    const reopen = await profileService.getUserRoles(userId as string);
    if (reopen.roles.length === 1) {
      return {
        displayName: "Create Parent Account",
        route: EMPLOYER_PARENT,
      };
    }
    return null;
  };

  const addCreateParentAccount = async () => {
    const employerHybrid = await checking();

    if (employerHybrid !== null && typeof employerHybrid === "object") {
      const buttonAlreadyExists = employerMenu.some(
        (menuItem) => menuItem.route === employerHybrid.route
      );

      if (!buttonAlreadyExists) {
        setEmployerMenu((prevMenu) => [...prevMenu, employerHybrid]);
      }
    }
  };

  useEffect(() => {
    addCreateParentAccount();
  }, []);

  const teenMenu = [
    {
      displayName: "Job Search",
      route: JOB_SEARCH,
    },
    {
      displayName: "Manage Jobs",
      route: TEEN_APPLICATIONS,
    },
    {
      displayName: "Profile",
      route: TEEN_PROFILE_PAGE,
    },
  ];
  const [parentMenu, setParentMenu] = React.useState([
    {
      displayName: " Manage Jobs",
      route: TEEN_APPLIED_JOBS,
    },
    {
      displayName: "Profile",
      route: PARENT_PROFILE,
    },
  ]);

  const checkingHybrid = async () => {
    const userId = localStorage.getItem(USER_ID);
    const reopen = await profileService.getUserRoles(userId as string);

    if (reopen.roles.length === 1) {
      return {
        displayName: "Create Employer Account",
        route: PARENT_EMPLOYER,
      };
    }
    return null;
  };

  const addCreateEmployerAccount = async () => {
    const parentHybrid = await checkingHybrid();

    if (parentHybrid !== null && typeof parentHybrid === "object") {
      const buttonAlreadyExists = parentMenu.some(
        (menuItem) => menuItem.route === parentHybrid.route
      );

      if (!buttonAlreadyExists) {
        setParentMenu((prevMenu) => [...prevMenu, parentHybrid]);
      }
    }
  };

  useEffect(() => {
    addCreateEmployerAccount();
  }, []);

  const handleNavigate = async () => {
    const auth0User = new Auth0User(user);
    const userResponse = await authService.authenticate(auth0User.auth0Id);
    dispatch(setAppUser(userResponse));
    dispatch(setAuth0User(auth0User));
    if (userResponse.user.currentRole === RoleId.EMPLOYER) {
      navigate(MANAGE_JOBS);
    } else if (userResponse.user.currentRole === RoleId.STUDENT) {
      navigate(JOB_SEARCH);
    } else if (userResponse.user.currentRole === RoleId.PARENT) {
      navigate(TEEN_APPLIED_JOBS);
    } else {
      navigate(PROFILE);
    }
  };

  const userId = localStorage.getItem(USER_ID) as string;
  useEffect(() => {
    void (async () => {
      if (appUser.user.currentRole === RoleId.EMPLOYER) {
        const res = await employerService.get(userId);
        dispatch(setUser(res));
      } else {
        const res = await profileService.getProfileData(userId);
        dispatch(setUser(res));
      }
    })();
  }, []);

  const userNames = useSelector(getUserProfile);

  const { firstName, lastName } = userNames.userProfile;
  const userName = firstName || lastName ? `${firstName} ${lastName}` : null;

  const [switchChecked, setSwitchChecked] = React.useState(false);

  const switchToParent = async () => {
    setSwitchChecked(!switchChecked);
    const userId = localStorage.getItem(USER_ID) as string;
    const roleId =  RoleId.PARENT;
    const res = await profileService.toggleSwitch(userId, roleId);
    navigate(TEEN_APPLIED_JOBS);
    window.location.reload();
  };

  const switchToEmployer = async () => {
    setSwitchChecked(!switchChecked);
    const userId = localStorage.getItem(USER_ID) as string;
    const roleId =  RoleId.EMPLOYER;
    const res = await profileService.toggleSwitch(userId, roleId);
    navigate(MANAGE_JOBS);
    window.location.reload();
  };

  const [show, setShow] = React.useState(true);

  const [reopen, setReopen] = React.useState({ roles: [] });

  useEffect(() => {
    void (async () => {
      const response = await profileService.getUserRoles(userId as string);
      setReopen(response);
      setShow(
        (appUser.user.currentRole === RoleId.EMPLOYER ||
          appUser.user.currentRole === RoleId.PARENT) &&
          response.roles.length > 1
      );
    })();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
              onClick={onNavOpen}
            >
              <MenuIcon />
            </IconButton>
            <Dialog
              fullScreen
              open={call}
              sx={{ width: "10rem" }}
              onClose={onNavClose}
            >
              <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={call}
              >
                <DrawerHeader>
                  {reopen.roles.length > 1 && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      {appUser.user.currentRole === RoleId.EMPLOYER && (
                        <>
                          <Button onClick={switchToParent}>
                            Switch to Parent
                          </Button>
                        </>
                      )}

                      {appUser.user.currentRole === RoleId.PARENT && (
                        <>
                          <Button onClick={switchToEmployer}>
                            Switch to Employer
                          </Button>
                        </>
                      )}
                    </Stack>
                  )}

                  <IconButton onClick={onNavClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />

                {appUser.user.currentRole === RoleId.EMPLOYER &&
                  (switchChecked ? (
                    <LayOut menu={parentMenu.slice(0, 3)} />
                  ) : (
                    <LayOut menu={employerMenu.slice(0, 3)} />
                  ))}
                {appUser.user.currentRole === RoleId.STUDENT && (
                  <LayOut menu={teenMenu} />
                )}
                {appUser.user.currentRole === RoleId.PARENT &&
                  (switchChecked ? (
                    <LayOut menu={employerMenu.slice(0, 3)} />
                  ) : (
                    <LayOut menu={parentMenu.slice(0, 3)} />
                  ))}
              </Drawer>
            </Dialog>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              onClick={handleNavigate}
            >
              trravo
            </Typography>

            {isAuthenticated && (
              <>
                <Button color="inherit">{userName}</Button>
                <LogoutButton />
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>

      <Main open={open} sx={{ pb: "40px" }}>
        <DrawerHeader />
        <Box style={{ minHeight: "85vh" }}>
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
};
