import { configureStore } from "@reduxjs/toolkit";
import employerProfileReducer from "./features/employerProfileSlice";
import jobReducer from "./features/jobSlice";
import parentCardReducer from "./features/parentCardSlice";
import teenSliceReducer from "./features/teenSlice";
import userSliceReducer from "./features/userSlice";
import LogRocket from "logrocket";

const middleware = (getDefaultMiddleware: any) =>
  getDefaultMiddleware().concat(LogRocket.reduxMiddleware());

export const store = configureStore({
  reducer: {
    job: jobReducer,
    employer: employerProfileReducer,
    parent: parentCardReducer,
    teen: teenSliceReducer,
    user: userSliceReducer,
  },
  middleware: (getDefaultMiddleware) => middleware(getDefaultMiddleware),
});
