import { createSlice } from "@reduxjs/toolkit";
import type TeenagerDTO from "../../services/teenager/teenager.dto";

const initialState = {
  users: [] as TeenagerDTO[],
};

export const teenSlice = createSlice({
  name: "teen",
  initialState,
  reducers: {
    addTeen: (state, action) => {
      state.users = [];
    },
    setTeens: (state, action) => {
      state.users = action.payload;
    },
    deactiveUsers: (state, action) => {
      const { results, teenager } = action.payload;
      state.users = state.users.map((teen: TeenagerDTO) => {
        if (teen.id === teenager.id) {
          teen.status = results.status;
        }
        return teen;
      });
    },
  },
});

export const getTeensData = (state: any) => state.teen.users;
export const getTeenProfile = (state: any) => state.teen;
export const { addTeen, setTeens, deactiveUsers } = teenSlice.actions;
export default teenSlice.reducer;
