import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PatternFormat from "react-number-format";
import type ApplicationDTO from "../../services/ApplicationDTO/Application.DTO";
import {
  validateContact,
  validateEmail,
  validateEmailID,
  validateFirstName,
  validateLastName,
  validateName,
  validateZipCode,
} from "../../lib/util/common/utils";
import NumericFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { currentUser, setUser } from "../../redux/features/userSlice";
import profileService from "../../services/profile/profile.service";
import type AppUser from "../../services/auth/AppUser";
import { USER_ID } from "../../lib/constants/localstorageitem";
interface ApplicationFormProps {
  jobApplication: ApplicationDTO;
  onSubmit: (jobApplication: ApplicationDTO) => void;
}

export const JobApplicationForm = ({
  jobApplication,
  onSubmit,
}: ApplicationFormProps) => {
  const [jobApplicationData, setJobApplicationData] = useState(jobApplication);
  const [errorFirstName, setErrorFirstName] = useState<string | null>(null);
  const [errorLastName, setErrorLastName] = useState<string | null>(null);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorContact, setErrorContact] = useState<string | null>(null);
  const [errorSummary, setErrorSummary] = useState<string | null>(null);
  const [checkBox, setCheckBox] = useState(false);
  const [open, setOpen] = React.useState(false);

  const {
    firstName,
    lastName,
    email,
    contactNumber,
    summary,
    price,
    priceType,
  } = jobApplicationData;

  const onPriceChange = (e: any) => {
    const value = e.target.value;
    const edited = { ...jobApplicationData };
    edited.price = value;
    setJobApplicationData(edited);
  };

  const appUser = useSelector(currentUser) as unknown as AppUser;
  const dispatch = useDispatch();
  const userId = localStorage.getItem(USER_ID) as string;
  useEffect(() => {
    void (async () => {
      const res = await profileService.get(userId);
      dispatch(setUser(res));
      setJobApplicationData((prevData) => ({
        ...prevData,
        firstName: res?.firstName ?? "",
        lastName: res?.lastName ?? "",
        email: res?.email ?? "",
        contactNumber: res?.contactNumber ?? "",
      }));
    })();
  }, []);

  const onSubmitClick = (jobApplicationData: ApplicationDTO): void => {
    if (isFormValid(jobApplicationData)) {
      onSubmit(jobApplicationData);
    }
  };

  const isFormValid = (jobApplicationData: ApplicationDTO): boolean => {
    const { firstName, lastName, email, contactNumber } = jobApplicationData;
    let nameErrors = validateFirstName(firstName);
    nameErrors = validateLastName(lastName);
    setErrorFirstName(validateFirstName(firstName));
    setErrorLastName(validateLastName(lastName));
    const emailError = validateEmail(email);
    setErrorEmail(emailError);
    const contactErrors = validateContact(contactNumber);
    setErrorContact(contactErrors);

    return (
      nameErrors === null &&
      emailError === null &&
      (contactNumber.length === 0 || contactErrors === null)
    );
  };

  const onPriceTypeChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ): void => {
    const { value } = event.target;
    const jobApplication = { ...jobApplicationData } as ApplicationDTO;
    jobApplication.priceType = value;
    setJobApplicationData(jobApplication);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;

    const jobApplication = { ...jobApplicationData } as ApplicationDTO;

    switch (target.name) {
      case "firstName":
        jobApplication.firstName = target.value;
        break;
      case "lastName":
        jobApplication.lastName = target.value;
        break;
      case "email":
        jobApplication.email = target.value;
        break;
      case "contactNumber":
        jobApplication.contactNumber = target.value;
        break;
      case "summary":
        jobApplication.summary = target.value;
        break;
      case "priceType":
        jobApplication.priceType = target.value;
        break;
      default:
        break;
    }

    setJobApplicationData(jobApplication);
  };
  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case "firstName":
        setErrorFirstName(validateFirstName(value));
        break;
      case "lastName":
        setErrorLastName(validateLastName(value));
        break;
      case "email":
        setErrorEmail(validateEmail(value));
        break;
      case "contactNumber":
        setErrorContact(validateContact(value));
        break;
      case "summary":
        setErrorSummary(validateSummary(value));
        break;

      default:
        break;
    }
  };

  const validateSummary = (value: string): string | null => {
    let message = null;
    if (value.length > 2000) {
      message = "description should be below 2000 characters";
    }
    return message;
  };
  const handleClickOpen = (e: any) => {
    setOpen(true);
  };

  const validatePrice = (value: string): string | null => {
    let message = null;
    if (!validateZipCode(value)) {
      message = " special characters and characters are not allowed";
    }
    return message;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box component="div" className="adjust">
      <TextField
        inputProps={{
          maxLength: 50,
        }}
        required
        error={errorFirstName !== null}
        helperText={errorFirstName}
        type={"text"}
        autoComplete="none"
        id="firstName"
        placeholder="Enter First Name"
        name="firstName"
        label="First Name"
        onBlur={onInputFocusOut}
        value={firstName}
        onChange={onInputChange}
        fullWidth
        FormHelperTextProps={{ style: { lineHeight: "initial" } }}
        sx={{ mt: 3, display: "inline-block", position: "relative" }}
      />

      <TextField
        inputProps={{
          maxLength: 50,
        }}
        required
        error={errorLastName !== null}
        helperText={errorLastName}
        type={"text"}
        autoComplete="none"
        id="lastName"
        placeholder="Enter Last Name"
        name="lastName"
        label="Last Name"
        onBlur={onInputFocusOut}
        value={lastName}
        onChange={onInputChange}
        fullWidth
        FormHelperTextProps={{ style: { lineHeight: "initial" } }}
        sx={{ mt: 3, display: "inline-block", position: "relative" }}
      />
      <TextField
        required
        error={errorEmail !== null}
        type={"email"}
        placeholder="Enter Your Email "
        label="Email"
        name="email"
        autoComplete="none"
        value={email}
        className="textFeildSize"
        onChange={onInputChange}
        onBlur={onInputFocusOut}
        helperText={errorEmail}
        fullWidth
        FormHelperTextProps={{ style: { lineHeight: "initial" } }}
        sx={{ mt: 3, display: "inline-block", position: "relative" }}
        disabled
      />

      <PatternFormat
        placeholder="Enter Phone Number"
        id="contact-Number"
        customInput={TextField}
        sx={{ mt: 3, display: "inline-block", position: "relative" }}
        label="Phone Number"
        variant="outlined"
        format="+1 (###)###-####"
        name="contactNumber"
        value={contactNumber}
        onChange={onInputChange}
        onBlur={onInputFocusOut}
        error={errorContact !== null}
        helperText={errorContact}
        fullWidth
        required
      />

      <TextField
        id="outlined-multiline-static"
        label="Describe yourself(optional)"
        placeholder="Enter About Yourself"
        name="summary"
        autoComplete="none"
        value={summary}
        onChange={onInputChange}
        onBlur={onInputFocusOut}
        error={errorSummary !== null}
        helperText={errorSummary}
        multiline
        rows={2}
        variant="outlined"
        sx={{ mt: 3, display: "inline-block", position: "relative" }}
        fullWidth
      />

      <NumericFormat
        placeholder="Enter Expected Price"
        label="Expected Price(optional)"
        name="price"
        value={price}
        onChange={onPriceChange}
        customInput={TextField}
        FormHelperTextProps={{ style: { lineHeight: "initial" } }}
        sx={{
          mt: 3,
          width: "50%",
        }}
        inputProps={{
          maxLength: 3,
        }}
        allowNegative={false}
        decimalScale={0}
      />
      <Select
        name="priceType"
        value={priceType}
        onChange={onPriceTypeChange}
        sx={{ mt: 3 }}
      >
        <MenuItem value={"perHr"}>$/Hr</MenuItem>
        <MenuItem value={"perJob"}>$/Job</MenuItem>
      </Select>

      <Stack direction={"row"} sx={{ marginTop: "1.4rem" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkBox}
                onChange={(e) => {
                  setCheckBox(e.target.checked);
                }}
              />
            }
            label=""
          />
        </FormGroup>
        <Typography
          sx={{ mt: "0.5rem", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          <Link>Accept terms& Conditions </Link>
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography>
              <>
                <Typography sx={{ fontWeight: "bold" }}>
                  Terms and Conditions: Use of User-Submitted Data
                </Typography>
                <Typography sx={{ marginTop: "1rem" }}>
                  By submitting any data or information to our platform, you
                  acknowledge and agree to the following terms and conditions:
                </Typography>
                <ol style={{ marginTop: "1rem" }}>
                  <li>
                    Ownership: You retain ownership of any data or information
                    you submit to our platform. However, by submitting such
                    data, you grant us a non-exclusive, worldwide, royalty-free
                    license to use, reproduce, modify, adapt, publish,
                    translate, distribute, and display the data for the purposes
                    stated in these terms.
                  </li>
                  <li>
                    Confidentiality: We respect your privacy and will treat all
                    user-submitted data as confidential information. We will
                    take reasonable measures to protect the confidentiality and
                    security of such data in accordance with applicable laws and
                    regulations.
                  </li>
                  <li>
                    Purpose of Use: The user-submitted data will be used solely
                    for the purpose of providing and improving our services,
                    including but not limited to enhancing the user experience,
                    personalization, analytics, and research. We may also use
                    the data to communicate with you regarding our services,
                    updates, and relevant promotional material.
                  </li>
                  <li>
                    Data Sharing: We may share user-submitted data with trusted
                    third parties, service providers, and business partners who
                    assist us in operating our platform or providing related
                    services. However, we will not sell, rent, or lease your
                    data to any third parties for marketing purposes without
                    your explicit consent.
                  </li>
                  <li>
                    Compliance: We will comply with applicable data protection
                    laws and regulations regarding the collection, storage,
                    processing, and sharing of user-submitted data. We encourage
                    you to review our Privacy Policy for more detailed
                    information on how we handle personal data.
                  </li>
                  <li>
                    User Responsibility: You are solely responsible for the
                    accuracy, legality, and compliance of the data you submit to
                    our platform. You represent and warrant that you have the
                    necessary rights and permissions to submit such data, and it
                    does not infringe upon any third-party rights.
                  </li>
                  <li>
                    Data Retention: We will retain user-submitted data for as
                    long as necessary to fulfill the purposes outlined in these
                    terms, or as required by law. Upon termination of your
                    account or withdrawal of your consent, we will delete or
                    anonymize your data, unless legal obligations or legitimate
                    interests require its further retention.
                  </li>
                  <li>
                    Amendments: These terms and conditions are subject to
                    change. We will notify you of any significant updates
                    through reasonable means, such as email or platform
                    notifications. Continued use of our platform after such
                    changes will constitute your acceptance of the revised
                    terms.
                  </li>
                  By using our platform and submitting data, you indicate that
                  you have read, understood, and agreed to these terms and
                  conditions.
                </ol>
              </>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{ marginRight: "2rem" }}
              color="success"
            >
              I Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
      <Button
        style={{ marginTop: "20px" }}
        onClick={() => {
          onSubmitClick(jobApplicationData);
        }}
        variant="contained"
        type={"button"}
        className="btnSubmit"
        disabled={!checkBox || errorContact != null}
      >
        Apply Job
      </Button>
    </Box>
  );
};
