import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { setAppUser, setAuth0User } from "../../redux/features/userSlice";
import HomePage from "./home/HomePage";
import Footer from "./home/Footer";
import HomeFilters from "./home/HomeFilter";
import Auth0User from "../../services/auth/Auth0User";
import authService from "../../services/auth/auth.service";
import { useDispatch } from "react-redux";
import {
  EMAIL_NOT_VERIFIED,
  MANAGE_JOBS,
  PROFILE,
} from "../../lib/constants/navigationUrls";
import { RoleId } from "../../lib/util/common/registration/utils";
import { USER_ID } from "../../lib/constants/localstorageitem";
export const PublicLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        if (user?.email_verified === true) {
          const auth0User = new Auth0User(user);
          const userResponse = await authService.authenticate(
            auth0User.auth0Id
          );
          dispatch(setAppUser(userResponse));
          localStorage.setItem(USER_ID, userResponse.user.id);
          dispatch(setAuth0User(auth0User));
          if (userResponse.user.currentRole === RoleId.EMPLOYER) {
            navigate(MANAGE_JOBS);
          } else {
            navigate(PROFILE);
          }
        } else {
          navigate(EMAIL_NOT_VERIFIED);
        }
      }
    };

    void getToken();
  }, [isAuthenticated]);

  return (
    <Stack
      direction={"column"}
      style={{ backgroundColor: "#9E80E2", height: "100vh" }}
    >
      <Stack direction={"column"}>
        <HomePage />
      </Stack>
      <Stack style={{ backgroundColor: "rgb(158, 128, 226)" }}>
        <HomeFilters onSubmit={() => {}} />
      </Stack>
      <Footer />
    </Stack>
  );
};
