import type JobDTO from "../../../services/job/dtos/job.dto";
import { format } from "timeago.js";

export const validateEmailID = (val: string) => {
  const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,3}$/i;
  return val?.match(mailRegex) ?? null;
};
export const validateName = (val: string) => {
  const name = "^[A-Z a-z ]+$";
  return val?.match(name) ?? null;
};
export const validateZipCode = (val: string) => {
  const name = /^(?!0{3})[0-9]{5}$/;
  return val?.match(name) ?? null;
};
export const validatePwd = (val: string) => {
  const password =
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})";
  return val?.match(password) ?? null;
};

export const validateZip = (val: string) => {
  const zip = /(\d{5})(\d{4})/;
  return val?.replace(zip, "$1-$2") ?? null;
};

export const getDuration = (date: string | Date | undefined) => {
  if (date !== undefined) {
    const givenDate = new Date(date);
    return format(givenDate);
  } else {
    return "";
  }
};

export const sortByDuration = (jobs: JobDTO[]) =>
  jobs.sort(
    (a: any, b: any) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

export const getNearestMinutes = (date: Date) => {
  const minute = date.getMinutes();
  const nearestMinute = Math.round(minute / 15) * 15;

  if (nearestMinute === 60) {
    date.setMinutes(0);
    date.setHours(date.getHours() + 1);
  } else {
    date.setMinutes(nearestMinute);
  }
  return date;
};

export const validateContact = (value: string): string | null => {
  let message = null;
  const pattern = /^\+1 \(\d{3}\)\d{3}-\d{4}$/;
  if (value === "") {
    message = "Phone Number is Required";
  } else if (!pattern.test(value)) {
    message = "Phone Number should be 10 digits ";
  }

  return message;
};

export const validateEmail = (value: string | null): string | null => {
  let message = null;
  if (value === "") {
    message = "Email is Required";
  } else if (validateEmailID(value as string) == null) {
    message = "Please enter a valid email ";
  }
  return message;
};

export const validateDescription = (value: string): string | null => {
  let message = null;
  if (value === "") {
    message = "Description is Required";
  } else if (value.length >= 2000) {
    message = "Max limit should be below 2000 characters";
  } else if (validateName(value as string) == null) {
    message = "Enter Alphabets only";
  }
  return message;
};

export const allowInterest = (val: string) => {
  const name = "^[A-Za-z, ]+$";
  return val?.match(name) ?? null;
};

export const validateInterest = (value: string): string | null => {
  let message = null;
  if (value === "") {
    message = "Area of Interests is Required";
  } else if (value.length >= 2000) {
    message = "Max limit should be below 2000 characters";
  } else if (allowInterest(value as string) == null) {
    message = "Enter Alphabets only";
  }
  return message;
};

export const validateFullName = (value: string): string | null => {
  if (value === null) {
    return null;
  }

  let message = null;
  if (value === "") {
    message = "Full Name is Required";
  } else if (value.length <= 2) {
    message = "Min 3 characters is required";
  } else if (value.length > 100) {
    message = "Max limit should be below 100 characters";
  } else if (validateName(value as string) == null) {
    message = "Enter Alphabets only";
  }
  return message;
};

export const validateFirstName = (value: string): string | null => {
  let message = null;
  if (value === "") {
    message = "First Name is Required";
  } else if (value.length <= 2) {
    message = "Min 3 characters is required";
  } else if (value.length > 50) {
    message = "Max limit should be below 50 characters";
  } else if (validateName(value as string) == null) {
    message = "Enter Alphabets only";
  }
  return message;
};

export const validateLastName = (value: string): any | null => {
  let message = null;
  if (value === "") {
    message = "Last Name is Required";
  } else if (value.length > 50) {
    message = "Max limit should be below 50 characters";
  } else if (validateName(value as string) == null) {
    message = "Enter Alphabets only";
  }
  return message;
};

export const validateZipCodeError = (value: string | null): string | null => {
  let message = null;
  if (value === "") {
    message = "ZipCode is Required";
  } else if (validateZipCode(value as string) == null) {
    message = "ZipCode should be 5 digits";
  }
  return message;
};

export const validateLength = (val: string, min: number, max: number) => {
  if (val === undefined) {
    return false;
  }

  const regex = /(<([^>]+)>)/gi;
  const cleanedValue = val.replace(regex, "");
  const length = cleanedValue.length;

  return length >= min && length <= max;
};
