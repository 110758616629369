import { Alert, Button, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getExistedJobs,
  setManageJobs,
} from "../../../redux/features/jobSlice";
import employerService from "../../../services/employer/employer.service";
import jobService from "../../../services/job/job.service";
import { currentUser } from "../../../redux/features/userSlice";
import { USER_ID } from "../../../lib/constants/localstorageitem";
import AppUser from "../../../services/auth/AppUser";
import ApplicationDTO from "../../../services/ApplicationDTO/Application.DTO";
import { useNavigate } from "react-router-dom";
import { MANAGE_JOBS } from "../../../lib/constants/navigationUrls";

const ApplicantsList = () => {
  const [open, setOpen] = useState(true);
  const [close, setClose] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [apply, setApply] = useState(false);

  const [message, setMessage] = useState("");
  const [key, setKey] = useState(0);


  const [opens, setOpens] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appUser = useSelector(currentUser) as unknown as AppUser;

  const id = localStorage.getItem(USER_ID) as string;

  const handleDeny = async (jobApp: ApplicationDTO, status: string) => {
    const result = await employerService.ApproveJob(id, status, jobApp);
    const applicants = await jobService.applicants(id);
    
    dispatch(setManageJobs(applicants));
    setMessage("You rejected this Applicant! ");
    setOpens(true)
    setTimeout(() => {
      navigate(MANAGE_JOBS);
    }, 3000);

    if (result.status === "Application is rejected") {
      setApply(true);
      setSnackbarOpen(true);
    }
    setOpen(true);
    setClose(false);
   
  };

  const handleAccept = async (jobApp: ApplicationDTO, status: string) => {
    const result = await employerService.ApproveJob(id, status, jobApp);
    const applicants = await jobService.applicants(id);
    
    dispatch(setManageJobs(applicants));
    setOpens(true);
    setMessage("You approved this Applicant !ThankYou");
    setTimeout(() => {
      navigate(MANAGE_JOBS);
    }, 1000);

    console.log(result.status, "job Status");
    if (result.status === "employerApproved") {
      setApply(true);

      setSnackbarOpen(true);
    }
    setClose(false);
    setOpen(true);
  };

  const teen = useSelector(getExistedJobs);

  return (
    <>
      <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        List of Applicants
      </Typography>
      <Snackbar
        open={opens}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => {
          setSnackbarOpen(false);
        }}

      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity={apply ? "success" : "error"}
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Stack direction="row">
        <Stack>
          <table>
          <tr>
              <th>Applicants Name</th>
              <th>Accept/Deny Applicants</th>
            </tr>

            {teen.applicants.map((applicant: any) => (
              <tr key={applicant.Teenager.id}>
                <td>
                  {`${applicant.Teenager.firstName} ${applicant.Teenager.lastName}`}{" "}
                </td>

                <td>
                  {applicant.status !== "EmployerRejected" && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        void handleAccept(applicant, "employerApproved");
                      }}
                      disabled={
                        applicant.status === "employerApproved" ||
                        applicant.status === "positionClosed"
                      }
                    >
                      {applicant.status === "employerApproved"
                        ? "Accepted"
                        : "Accept"}
                    </Button>
                  )}
                  {applicant.status !== "employerApproved" && (
                    <Button
                      variant="contained"
                      color="error"
                      key={key}
                      sx={{ marginLeft: "5rem" }}
                      onClick={() => {
                        void handleDeny(applicant, "EmployerRejected");
                      }}
                      disabled={
                        applicant.status === "EmployerRejected" ||
                        applicant.status === "positionClosed"
                      }
                    >
                      {applicant.status === "EmployerRejected" ||
                      applicant.status === "positionClosed"
                        ? "Denied"
                        : "Deny"}
                    </Button>
                  )}
                
                </td>
              </tr>
            ))}
          </table>

        </Stack>
      </Stack>
    </>
  );
};

export default ApplicantsList;
