class EmployerDTO {
  profileStatus: any;
  id: number | null;
  companyName: string;
  firstName: string;
  lastName: string;
  ssn: string;
  email: string;
  contactNumber: string;
  password: string;
  bio?: string;
  address: any;
  userType?: string;
  avatar?: string;
  status?: string;
  emailOnNewApplication?: boolean;
  user: any;

  constructor(data: Partial<EmployerDTO> = {}) {
    this.id = data.id ?? null;
    this.companyName = data.companyName ?? "";
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.ssn = data.ssn ?? "";
    this.email = data.email ?? "";
    this.contactNumber = data.contactNumber ?? "";
    this.password = data.password ?? "";
    this.address = data.address ?? "";
    this.bio = data.bio ?? "";
    this.address = data.address ?? {};
    this.userType = data.userType ?? "";
    this.avatar = data.avatar ?? "";
    this.status = data.status ?? "";
    this.emailOnNewApplication = data.emailOnNewApplication ?? false;
    this.user = data.user ?? {};
  }
}
export default EmployerDTO;
