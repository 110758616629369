import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import PatternFormat from "react-number-format";
import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useEffect, useState, type FC } from "react";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  currentUser,
  getUserProfile,
  setUser,
} from "../../redux/features/userSlice";
import profileService from "../../services/profile/profile.service";
import type ProfileDTO from "../../services/profile/profile.dto";
import {
  validateContact,
  validateFirstName,
  validateLastName,
  validateZipCode,
  validateZipCodeError,
} from "../../lib/util/common/utils";
import { useNavigate } from "react-router-dom";
import {
  CHILD_INFO,
  JOB_SEARCH,
  MANAGE_JOBS,
  TEEN_APPLIED_JOBS,
  TEEN_PROFILE,
} from "../../lib/constants/navigationUrls";
import { RoleId, Roles } from "../../lib/util/common/registration/utils";
import type AppUser from "../../services/auth/AppUser";
import NumericFormat from "react-number-format";
import Loader from "../../components/Loader";
import { USER_ROLE_ID, USER_ID } from "../../lib/constants/localstorageitem";
import childProfileService from "../../services/childProfile/childProfile.service";
import { addTeen, setTeens } from "../../redux/features/teenSlice";
import teenagerProfileService from "../../services/teenagerProfile/teenagerProfile.service";
import employerService from "../../services/employer/employer.service";

const TextField = styled(MuiTextField)({
  "& .MuiFormHelperText-root": {
    width: 250,
  },
});

const CONTACT_NUMBER = "contact-number";
const FIRST_NAME = "first-name";
const LAST_NAME = "last-name";
const ZIP_CODE = "zip-code";

const Profile: FC = () => {
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contactNumber, setContactNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");

  // Errors
  const [errorContact, setErrorContact] = useState<string | null>(null);
  const [errorFirstName, setErrorFirstName] = useState<string | null>(null);
  const [errorLastName, setErrorLastName] = useState<string | null>(null);
  const [errorZipCode, setErrorZipCode] = useState<string | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setLoading(true);
    void (async () => {
      const userId = localStorage.getItem(USER_ID);

      if (appUser.user.currentRole === RoleId.EMPLOYER) {
        const res = await employerService.get(userId as string);
        dispatch(setUser(res));
        if (appUser.user.currentRole) {
          localStorage.setItem(
            USER_ROLE_ID,
            appUser.user.currentRole.toString()
          );
        }
        if (userId != null) {
          const childInfoRes = await childProfileService.get(userId);
          if (Object.keys(childInfoRes).length > 0) {
            dispatch(addTeen(childInfoRes));
            navigate(TEEN_APPLIED_JOBS); // Navigate to the search page
          } else {
            setContactNumber(res?.contactNumber);
            setEmail(res?.email ?? "");
            setFirstName(res?.firstName);
            setLastName(res?.lastName);
            setZipCode(res?.zipCode);
            setLoading(false);
          }
        } else {
          setContactNumber(res?.contactNumber);
          setEmail(res?.email ?? "");
          setFirstName(res?.firstName);
          setLastName(res?.lastName);
          setZipCode(res?.zipCode);
          setLoading(false);
        }
      } else {
        const res = await profileService.get(userId as string);
        dispatch(setUser(res));
        if (appUser.user.currentRole) {
          localStorage.setItem(
            USER_ROLE_ID,
            appUser.user.currentRole.toString()
          );
        }
        if (validateZipCode(res?.zipCode) != null && userId != null) {
          if (appUser.user.currentRole === RoleId.PARENT) {
            const childInfoRes = await childProfileService.get(userId);
            if (Object.keys(childInfoRes).length > 0) {
              dispatch(addTeen(childInfoRes));
              navigate(TEEN_APPLIED_JOBS); // Navigate to the search page
            } else {
              setContactNumber(res?.contactNumber);
              setEmail(res?.email ?? "");
              setFirstName(res?.firstName);
              setLastName(res?.lastName);
              setZipCode(res?.zipCode);
              setLoading(false);
            }
          } else {
            const teenProfileRes = await teenagerProfileService.get(userId);
            if (Object.keys(teenProfileRes).length > 0) {
              dispatch(setTeens(teenProfileRes));
              navigate(JOB_SEARCH); // Navigate to the search page
            } else {
              setContactNumber(res?.contactNumber);
              setEmail(res?.email ?? "");
              setFirstName(res?.firstName);
              setLastName(res?.lastName);
              setZipCode(res?.zipCode);
              setLoading(false);
            }
          }
        } else {
          setContactNumber(res?.contactNumber);
          setEmail(res?.email ?? "");
          setFirstName(res?.firstName);
          setLastName(res?.lastName);
          setZipCode(res?.zipCode);
          setLoading(false);
        }
      }
    })();
  }, []);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;

    switch (target.name) {
      case FIRST_NAME:
        setFirstName(target.value);
        break;
      case LAST_NAME:
        setLastName(target.value);
        break;
      case ZIP_CODE:
        setZipCode(target.value);
        break;
      case CONTACT_NUMBER:
        setContactNumber(target.value);
        break;
      default:
        break;
    }
  };

  const isFormValid = (): boolean => {
    const firstNameErrors = validateFirstName(firstName ?? null);
    setErrorFirstName(firstNameErrors);
    const lastNameErrors = validateLastName(lastName ?? null);
    setErrorLastName(lastNameErrors);
    const contactErrors = validateContact(contactNumber ?? null);
    setErrorContact(contactErrors);
    const zipCodeErrors = validateZipCodeError(zipCode ?? null);
    setErrorZipCode(zipCodeErrors);

    return (
      contactErrors === null &&
      firstNameErrors === null &&
      lastNameErrors === null &&
      zipCodeErrors === null
    );
  };

  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case FIRST_NAME:
        setErrorFirstName(validateFirstName(value));
        break;
      case LAST_NAME:
        setErrorLastName(validateLastName(value));
        break;
      case ZIP_CODE:
        setErrorZipCode(validateZipCodeError(value));
        break;
      case CONTACT_NUMBER:
        setErrorContact(validateContact(value));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;
    const profileData: ProfileDTO = {
      firstName,
      lastName,
      zipCode,
      contactNumber,
    };
    try {
      const userId = localStorage.getItem(USER_ID);
      const res = await profileService.update(userId as string, profileData);
      dispatch(setUser(profileData));
    } catch (e) {
      console.log("Profile Update error", e);
    }
    if (currentRole === RoleId.EMPLOYER) {
      navigate(CHILD_INFO);
    } else if (currentRole === RoleId.PARENT) {
      navigate(CHILD_INFO);
    } else if (currentRole === RoleId.STUDENT){
      navigate(TEEN_PROFILE);
    }
    else {
      navigate(CHILD_INFO)
    }
  };

  const storedRoleId = localStorage.getItem(USER_ROLE_ID);

  const currentRole = storedRoleId != null && parseInt(storedRoleId);
  return (
    <Grid justifyContent="center" container direction="row">
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <Card sx={{ width: "auto", marginTop: "2rem", marginBottom: "2rem" }}>
          {currentRole === RoleId.STUDENT ? (
            <>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "Impact",
                  mb: 2,
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                Student Profile
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  marginLeft: "3rem",
                  marginRight: "3rem",
                }}
              >
                Student Details
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  mb: 2,
                  textAlign: "center",
                  marginTop: "2rem",
                  fontFamily: "Impact",
                }}
              >
                Parent Profile
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  marginLeft: "3rem",
                  marginRight: "3rem",
                  fontWeight: "bold",
                }}
              >
                Parent Details
              </Typography>
            </>
          )}

          <Stack direction="column" alignItems="center" justifyContent="center">
            <div
              style={{
                marginTop: "0.6rem",
                width: "80%",
                // alignItems: "center",
                justifyContent: "center",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <TextField
                error={errorFirstName !== null}
                fullWidth
                helperText={errorFirstName}
                id="FName"
                label="First Name"
                name={FIRST_NAME}
                onBlur={onInputFocusOut}
                onChange={onInputChange}
                placeholder="Enter First Name"
                required
                sx={{ mt: "0.6rem", width: "80%", marginRight: "1rem" }}
                type="text"
                value={firstName}
                variant="standard"
              />

              <TextField
                error={errorLastName !== null}
                fullWidth
                helperText={errorLastName}
                id="LName"
                label="Last Name"
                name={LAST_NAME}
                onBlur={onInputFocusOut}
                onChange={onInputChange}
                placeholder="Enter Last Name"
                required
                sx={{ mt: "0.6rem", width: "80%" }}
                type="text"
                value={lastName}
                variant="standard"
              />
            </div>

            <TextField
              autoComplete="none"
              className="textFeildSize"
              disabled
              id="Email-ID"
              label="Email"
              name="email"
              placeholder="Enter Your Email "
              required
              sx={{ mt: "0.6rem", width: "80%" }}
              type={"email"}
              value={email}
              variant="standard"
            />

            <PatternFormat
              customInput={TextField}
              error={errorContact !== null}
              format="+1 (###)###-####"
              helperText={errorContact}
              id="contact-Number"
              label="Phone Number"
              name={CONTACT_NUMBER}
              onBlur={onInputFocusOut}
              onChange={onInputChange}
              placeholder="Enter Phone Number"
              required
              sx={{ mt: "0.6rem", width: "80%" }}
              value={contactNumber}
              variant="standard"
            />

            <NumericFormat
              placeholder="Enter Zip Code"
              label="Zip Code"
              sx={{ mt: "0.6rem", width: "80%" }}
              customInput={TextField}
              onChange={onInputChange}
              onBlur={onInputFocusOut}
              variant="standard"
              name={ZIP_CODE}
              type="tel"
              value={zipCode}
              error={errorZipCode !== null}
              helperText={errorZipCode}
              decimalScale={0}
              allowNegative={false}
              inputProps={{
                maxLength: 5,
              }}
              required
            />
            <Stack
              direction="row"
              sx={{ marginTop: "2rem", marginBottom: "1rem" }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "7rem" }}
              >
                Next
              </Button>
            </Stack>
          </Stack>
        </Card>
      )}
    </Grid>
  );
};
export default Profile;
