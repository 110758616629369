import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import EmployerDTO from "../../services/employer/employer.dto";

interface EmployerProfileState {
  employer: EmployerDTO;
}

const initialState: EmployerProfileState = {
  employer: new EmployerDTO(),
};

export const employerProfileSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setEmployer: (state, action: PayloadAction<EmployerDTO>) => {
      state.employer = action.payload;
    },
  },
});

export const currentEmployer = (state: { employer: EmployerProfileState }) =>
  state.employer.employer;

export const { setEmployer } = employerProfileSlice.actions;

export default employerProfileSlice.reducer;
