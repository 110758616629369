import React, { useState } from "react";
import UserProfileCard from "./UserProfileCard";
import UserProfileDTO from "../services/UserProfile/UserProfile.dto";
import { useDispatch, useSelector } from "react-redux";
import AppUser from "../services/auth/AppUser";
import { currentUser, setUser } from "../redux/features/userSlice";
import profileService from "../services/profile/profile.service";
import { USER_ID } from "../lib/constants/localstorageitem";

const UserProfile = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const dispatch = useDispatch();
  const userId = localStorage.getItem(USER_ID) as string;

  const onSaveHandle = async (details: UserProfileDTO) => {
    const req = await profileService.updateProfile(userId, details);
    dispatch(setUser(req));
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div>
      <UserProfileCard
        key={refreshKey}
        userData={new UserProfileDTO()}
        onSave={onSaveHandle}
      />
    </div>
  );
};

export default UserProfile;
