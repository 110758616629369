import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parentDetails: [
    {
      id: 1,
      firstName: "Rajini",
      lastName: "Dhankad",
      contactNumber: 8710384671,
      email: "RajiniD@gmail.com",
      date: "12-12-2022",
    },
  ],
};

export const parentCardSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {
    setParent: (state, action) => {
      return action.payload;
    },
  },
});

export const getParentCard = (state: any) => state.parent.parentDetails;
export const { setParent } = parentCardSlice.actions;
export default parentCardSlice.reducer;
