import AppConsts from "../../lib/constants/appConst";
import http from "../httpService";
import type RegistrationDTO from "./registration.dto";

class RegistrationService {
  readonly baseUrl = AppConsts.appBaseUrl;

  // Register a new user irrespective of their role
  public async register(details: RegistrationDTO): Promise<RegistrationDTO> {
    const result = await http.post(`${this.baseUrl}/regester`, details);
    return result.data as RegistrationDTO;
  }
}

export default new RegistrationService();
