import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { LOGIN, SIGN_UP } from "../../lib/constants/navigationUrls";

function GuestUser() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      sx={{
        maxWidth: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1rem",
      }}
    >
      <AccountCircleIcon
        sx={{
          height: "10rem",
          width: "300px",
          alignItems: "center",
          justifyContent: "center",
          color: "#9E80E2",
        }}
      />

      <Button variant="outlined" onClick={() => navigate(SIGN_UP)}>
        Register
      </Button>
      <Typography sx={{ textAlign: "center" }}>OR</Typography>
      <Button variant="outlined" onClick={() => navigate(LOGIN)}>
        Login
      </Button>
    </Stack>
  );
}

export default GuestUser;
