import AddressDTO from "../../address/AddressDTO";
import EmployerDTO from "../../employer/employer.dto";

class JobDTO {
  id?: number | null;
  title: string;
  firstName?: string;
  lastName?: string;
  price: string;
  priceType: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  address: AddressDTO;
  employer: EmployerDTO;
  isActive?: boolean;
  duration?: string | null;
  createdAt?: Date;
  isRemote?: boolean;
  status?: boolean;
  summary?:String;

  constructor(data: Partial<JobDTO> = {}) {
    this.id = data.id ?? null;
    this.title = data.title ?? "";
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.price = data.price ?? "";
    this.priceType = data.priceType ?? "perHr";
    this.description = data.description ?? "";
    this.address = data.address ?? new AddressDTO();
    this.employer = data.employer ?? new EmployerDTO();
    this.isActive = data.isActive ?? true;
    this.duration = data.duration ?? null;
    this.isRemote = data.isRemote ?? false;
    this.startDate = data.startDate ?? new Date(0);
    this.endDate = data.endDate ?? new Date(0);
    this.createdAt = data.createdAt ?? new Date(0);
    this.status = data.status ?? false;
  }
}
export default JobDTO;
