import { useContext, useEffect } from "react";
import ApplyJobCard from "../../teenager/ApplyJobCard";
import { AnalyticsContext } from "../../../context/analytics";
import { Waypoint } from "react-waypoint";
import { currentUser } from "../../../redux/features/userSlice";
import AppUser from "../../../services/auth/AppUser";
import { useSelector } from "react-redux";

interface Props {
  jobs: any[];
  loadMore: () => void;
}

export const JobList = ({ jobs, loadMore }: Props) => {
  const analytics = useContext(AnalyticsContext);
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const currentRole = appUser.user.currentRole;

  useEffect(() => {
    analytics.page();
  }, []);

  return (
    <>
      {jobs.map((job: any, i: number) => (
        <>
          <ApplyJobCard
            key={job.id}
            jobDetails={{
              id: job.id,
              title: job.title,
              duration: job.duration,
              totalApplicants: job.totalApplicants,
              firstName: job.firstName,
              lastName: job.lastName,
              startDate: job.startDate,
              endDate: job.endDate,
              price: job.price,
              priceType: job.priceType,
              description: job.description,
              address: job.address,
              employer: job.employer,
              createdAt: job.createdAt,
            }}
          />
          {i === jobs.length - 3 && (
            <Waypoint
              onEnter={() => {
                loadMore();
              }}
            />
          )}
        </>
      ))}
    </>
  );
};
