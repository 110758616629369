import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./components/Routes";
import Auth0ProviderWithHistory from "./components/Auth/Auth0ProviderWithHistory";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./theme/light";
import Main from "./context/analytics";
import LogRocket from "logrocket";
LogRocket.init("9fncf8/trravo-dev");

const theme = createTheme(lightTheme);
function App() {
  return (
    <Main>
      <div className="App">
        <Provider store={store}>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <ThemeProvider theme={theme}>
                <AppRoutes />
              </ThemeProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </Provider>
      </div>
    </Main>
  );
}

export default App;
