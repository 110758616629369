import { AppBar, Grid, Stack, Toolbar, Typography } from "@mui/material";
import trravoLogo from "../../../assets/trravo1.png";
import Footer from "./Footer";
import Teenimage from "../../../assets/teen1.jpg";
import Teenimage2 from "../../../assets/teen2.jpg";
import { useNavigate } from "react-router-dom";
import RegistrationHeader from "../RegistartionHeader";

function AboutPage() {
  const navigate = useNavigate();
  return (
    <>
      <RegistrationHeader />
      <Typography
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        About trravo
      </Typography>
      <Stack sx={{ px: 2 }}>
        <Stack sx={{ mt: "3rem" }}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={6}>
              <img
                src={Teenimage}
                alt="teen1"
                style={{ height: "20rem", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack sx={{ p: 2 }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                  The Problem
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  To post job about household help, adults use social platforms
                  like Facebook and Nextdoor. However, teenagers don’t use these
                  platforms as much. Even in this digital age, they often rely
                  on traditional methods such as community center notice boards
                  to find jobs. But not anymore!
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            direction="row-reverse"
            alignItems="center"
            sx={{ mt: "3rem", marginBottom: "6rem" }}
          >
            <Grid item xs={12} sm={6}>
              <img
                src={Teenimage2}
                alt="teen2"
                style={{ height: "20rem", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack sx={{ p: 2 }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
                  The Solution
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Trravo connects teenagers with a wide variety of job
                  opportunities in their neighborhood. From babysitting to lawn
                  care, dog walking to house cleaning, you can find it all here.
                  With this platform, finding a job is easy and hassle-free. At
                  Trravo, we take the safety of our users very seriously. We
                  want our teenagers to work for their neighbors with peace of
                  mind, knowing that they are in safe hands. That’s why we have
                  a thorough background check process for all job posts to
                  ensure that they are trustworthy individuals. So if you’re a
                  teenager looking for a safe and reliable job in your
                  neighborhood, look no further than Trravo. Join our community
                  today and start finding the perfect job for you!
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}

export default AboutPage;
