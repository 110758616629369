import { AxiosRequestConfig } from "axios";
import http from "../httpService";
import type JobDTO from "../job/dtos/job.dto";
import type JobApplication from "./JobApplication.dto";

class TeenagerService {
  private readonly teenagerServiceUrl = `teenager`;

  
  public async getJobs(id: string, page?: number): Promise<JobDTO> {
    const url = page
      ? `${this.teenagerServiceUrl}/${id}/apply/jobs?page=${page}`
      : `${this.teenagerServiceUrl}/${id}/apply/jobs`;
  
    const result = await http.get(url);
    return result.data as JobDTO;
  }
  

  public async applyJob(
    userId: string,
    jobDetails: JobApplication
  ): Promise<JobApplication> {
    const result = await http.post(`teen/apply/${userId}`, jobDetails);
    return result.data as JobApplication;
  }
}

export default new TeenagerService();
