class UserProfileDTO {
    firstName: string;
    lastName: string;
    email: string;
   contactNumber: string;
    profileImage?: File | null;


    constructor(data: Partial<UserProfileDTO> = {}){
        this.firstName = data.firstName ?? "";
        this.lastName = data.lastName ?? "";
        this.email = data.email ?? "",
        this.contactNumber = data.contactNumber?? "";
        this.profileImage = data.profileImage || null;
    }
}

export default UserProfileDTO;