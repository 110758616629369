import { JobApplicationForm } from "./ApplicationForm";
import ApplicationDTO from "../../services/ApplicationDTO/Application.DTO";

import teenagerService from "../../services/teenager/teenager.service";
import { USER_ID } from "../../lib/constants/localstorageitem";

interface IProps {
  id?: number | null;
  name?: string;
  handleClose: Function;
  setSnackbarOpen: Function;
  setApply: Function;
  setMessage: Function;
}

export default function ApplicationRegistration({
  id,
  handleClose,
  setSnackbarOpen,
  setApply,
  setMessage,
  name,
}: IProps) {
  const userId = localStorage.getItem(USER_ID) as string;

  const onSubmitJobApplication = async (jobApp: ApplicationDTO) => {
    try {
      const result = await teenagerService.applyJob(userId, jobApp);
      setApply(true);
      setMessage("Job Applied Successfully");

      setSnackbarOpen(true);
      handleClose();
    } catch (error: any) {
      if (error.response.status === 400) {
        setMessage("Job already applied");
      }
      else{
        setMessage("Please contact our support team ,there is a technical issue in site ");
      }
      setApply(false);
      setSnackbarOpen(true);
      handleClose();
    }
  };

  return (
    <div className="teenReg">
      <JobApplicationForm
        jobApplication={new ApplicationDTO({ jobId: id })}
        onSubmit={onSubmitJobApplication}
      />
    </div>
  );
}
