import { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import type JobDTO from "../../../services/job/dtos/job.dto";
import type AddressDTO from "../../../services/address/AddressDTO";
import Address from "../../../components/Address";
import { validateLength, validateZip } from "../../../lib/util/common/utils";
import { useSelector } from "react-redux";
import { currentUser } from "../../../redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import ReactQuill from "react-quill";
import DateRangeDTO from "../../../services/job/dtos/date.dto";
import NumericFormat from "react-number-format";
import { MANAGE_JOBS } from "../../../lib/constants/navigationUrls";

interface JobFormProps {
  job: JobDTO;
  onApply: (job: JobDTO) => void;
}

const NewJobForm = (props: JobFormProps) => {
  const { onApply } = props;

  const [job, setJob] = useState<JobDTO>(props.job);
  const [titleError, setTitleError] = useState<boolean>();
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [priceError, setPriceError] = useState<boolean>();
  const [address1Error, setAddress1Error] = useState<boolean>();
  const [address2Error, setAddress2Error] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>();
  const [stateError, setStateError] = useState<boolean>();
  const [zipCodeError, setZipCodeError] = useState<boolean>();
  const [countryError, setCountryError] = useState<boolean>();
  const [zipHelperText, setZipHelperText] = useState<string>();
  const [searchAddressError, setSearchAddressError] = useState<string | null>();
  const address1Input = useRef();
  const address2Input = useRef();
  const cityInput = useRef(null);
  const stateInput = useRef(null);
  const zipInput = useRef(null);
  const countryInput = useRef(null);

  const [disable, setDisabled] = useState(false);

  const { title, description, price, priceType, address, startDate, endDate } =
    job;

  const navigate = useNavigate();
  const user = useSelector(currentUser);
  const id = user.id;

  const onTitleChange = (e: any) => {
    const value = e.target.value;
    if (value.length >= 5 && value.length <= 50) {
      setTitleError(false);
      const edited = { ...job };
      edited.title = value;
      setJob(edited);
    } else {
      setTitleError(true);
    }
  };

  const onPriceChange = (e: any) => {
    const value = e.target.value;
    const edited = { ...job };
    edited.price = value;
    if (value.length === 0) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
    setJob(edited);
  };

  const onPriceTypeChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ): void => {
    const { value } = event.target;
    const edited = { ...job };
    edited.priceType = value;
    setJob(edited);
  };

  const onDescriptionChange = (val: string) => {
    const edited = { ...job };
    edited.description = val;

    if (val.length > 0 && validateLength(val, 10, 6000)) {
      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }
    setJob(edited);
  };

  const onApplyClick = () => {
    const titleError = !title;
    const descriptionError = !description;
    // @ts-expect-error
    const address1Error = !address1Input?.current?.value;
    // @ts-expect-error
    const address2Error = !address2Input?.current?.value;
    // @ts-expect-error
    const cityError = !cityInput?.current?.value;
    // @ts-expect-error
    const stateError = !stateInput?.current?.value;
    // @ts-expect-error
    const zipCodeError = !zipInput?.current?.value;
    // @ts-expect-error
    const countryError = !countryInput?.current?.value;
    const priceError = !price;

    setTitleError(titleError);
    setDescriptionError(descriptionError);
    setAddress1Error(address1Error);
    setAddress2Error(address2Error);
    setCityError(cityError);
    setStateError(stateError);
    setZipCodeError(zipCodeError);
    setCountryError(countryError);
    setPriceError(priceError);

    if (
      titleError ||
      descriptionError ||
      address1Error ||
      cityError ||
      stateError ||
      zipCodeError ||
      countryError
    ) {
      return;
    }
    const dateRange = new DateRangeDTO({
      startDate:
        startDate != null
          ? moment(startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      endDate:
        endDate != null
          ? moment(endDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
    });

    const edited = {
      ...job,
      dateRange,
      employerId: id,
      startDate: moment.utc(startDate).utcOffset(0).toDate(),
      endDate: moment.utc(endDate).utcOffset(0).toDate(),
    };
    onApply(edited);
    setDisabled(true);
  };

  const onStartDateChange = (val: Date) => {
    if (moment(val) > moment(endDate)) {
      setJob({ ...job, startDate: val, endDate: val });
    } else {
      setJob({ ...job, startDate: val });
    }
  };

  const onEndDateChange = (val: Date) => {
    setJob({ ...job, endDate: val });
  };

  const getAddressHandler = (address: AddressDTO) => {
    const edited = { ...job };
    if (Object.keys(address).length !== 0) {
      edited.address = address;
      const { address1, city, state, zipCode } = address;
      setJob(edited);
      setAddress1Error(false);
      setAddress2Error(false);
      setCityError(false);
      setStateError(false);
      setZipCodeError(false);
      setCountryError(false);
      if (address1 === "" || city === "" || state === "" || zipCode === "") {
        setSearchAddressError("Choose a valid address");
      }

      if (
        address1 &&
        address1?.length > 0 &&
        city &&
        city?.length > 0 &&
        state &&
        state?.length > 0 &&
        zipCode &&
        zipCode?.length > 0
      ) {
        setSearchAddressError(null);
      }
      // @ts-expect-error
      address1Input.current.value = address.address1;
      // @ts-expect-error
      address2Input.current.value = address.address2;
      // @ts-expect-error
      cityInput.current.value = address.city;
      // @ts-expect-error
      stateInput.current.value = address.state;
      // @ts-expect-error
      zipInput.current.value = address.zipCode;
      // @ts-expect-error
      countryInput.current.value = address.country;
    }
  };

  const address1ChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setAddress1Error(false);
      // @ts-expect-error
      address1Input.current.value = value;
    } else {
      setAddress1Error(true);
    }
  };

  const address2ChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      // @ts-expect-error
      address2Input.current.value = value;
    }
  };
  const cityChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setCityError(false);
      // @ts-expect-error
      cityInput.current.value = value;
      const updatedAddress = { ...job.address, city: value } as AddressDTO;
      setJob({ ...job, address: updatedAddress });
    } else {
      setCityError(true);
    }
  };
  const stateChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setStateError(false);
      // @ts-expect-error
      stateInput.current.value = value;
      const updatedAddress = { ...job.address, state: value } as AddressDTO;
      setJob({ ...job, address: updatedAddress });
    } else {
      setStateError(true);
    }
  };
  const onZipFocusChange = (e: any) => {
    const value = e.target.value;

    if (value.length === 0) {
      setZipCodeError(true);
      setZipHelperText("Zip Code is required");
    } else {
      setZipCodeError(false);
    }
  };

  const zipChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length === 5) {
      setZipCodeError(false);
      const updatedAddress: AddressDTO = { ...job.address, zipCode: value };
      setJob({ ...job, address: updatedAddress });
    } else if (value.length === 9) {
      const updatedAddress = {
        ...job.address,
        zipCode: validateZip(value),
      } satisfies AddressDTO;
      setJob({ ...job, address: updatedAddress });
      setZipCodeError(false);
      // @ts-expect-error
      zipInput.current.value = validateZip(value);
    } else {
      setZipCodeError(true);
      setZipHelperText("Please enter a valid zipcode");
    }
  };
  const countryChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setCountryError(false);
      // @ts-expect-error
      countryInput.current.value = value;
      const updatedAddress: AddressDTO = { ...job.address, country: value };
      setJob({ ...job, address: updatedAddress });
    } else {
      setCountryError(true);
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < 600 && window.innerHeight < 1000
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600 && window.innerHeight < 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <Stack spacing={2} sx={{ mr: 50, width: "100%", height: "auto" }}>
          <TextField
            required
            name="title"
            defaultValue={title}
            placeholder="Job Title"
            label="Job Title"
            type={"text"}
            fullWidth
            onChange={onTitleChange}
            onBlur={onTitleChange}
            error={titleError}
            helperText={
              titleError &&
              "Job Title is required and should be between 5 to 50 characters"
            }
            style={{ height: "4rem" }}
            InputProps={{
              style: {
                height: "4rem",
                padding: "0.5rem",
              },
            }}
          />

          {/* <TextEditor getDescription={descriptionChangeHandler} /> */}
          <div style={{ height: "38rem" }}>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={onDescriptionChange}
              placeholder="Job Description along with Working Hours & Payment Terms"
              style={{ width: "100%", height: "93%" }}
            />
          </div>
          <Typography
            component={"span"}
            sx={{
              color: "#d32f2f",
              fontSize: "0.75rem",
              mt: 0,
              mb: 4,
              p: 0,
              fontWeight: 400,
            }}
            color="error"
          >
            {descriptionError &&
              "Job description is required and should be between 10 to 2000 characters"}
          </Typography>
          <div
            style={{
              height: "4rem",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NumericFormat
              placeholder="Enter Price"
              label="Enter Price"
              customInput={TextField}
              name="price"
              value={price}
              onChange={onPriceChange}
              allowNegative={false}
              decimalScale={0}
              inputProps={{
                maxLength: 3,
                style: { height: "40px" },
              }}
              sx={{ height: "66px", width: "50%" }}
            />

            <Select
              name="priceType"
              value={priceType}
              onChange={onPriceTypeChange}
              sx={{ width: "10%", height: "4.5rem", marginTop: "0.3rem" }}
            >
              <MenuItem value={"perHr"} sx={{ height: "4rem" }}>
                $/Hr
              </MenuItem>
              <MenuItem value={"perJob"} sx={{ height: "4rem" }}>
                $/Job
              </MenuItem>
            </Select>
          </div>
          <Stack direction="column">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en"
            >
              <Stack
                direction={"row"}
                spacing={1}
                style={{ marginTop: "1.5rem", width: "100%" }}
              >
                <DatePicker
                  label="Start Date"
                  minDate={moment(new Date())}
                  value={moment(startDate)}
                  format="MM/DD/YYYY"
                  onChange={(date) => {
                    if (date !== null) {
                      date.startOf("day");
                      onStartDateChange(moment(date).toDate());
                    } else {
                      const currentDate = new Date();
                      currentDate.setHours(0, 0, 0, 0);
                      onStartDateChange(currentDate);
                    }
                  }}
                  sx={{ width: "50%" }}
                />
                <DatePicker
                  label="End Date"
                  minDate={moment(startDate)}
                  value={moment(endDate)}
                  format="MM/DD/YYYY"
                  onChange={(date) => {
                    if (date !== null) {
                      date.startOf("day");
                      onEndDateChange(date.toDate());
                    } else {
                      const currentDate = moment(new Date()).startOf("day");
                      onEndDateChange(currentDate.toDate());
                    }
                  }}
                  sx={{ width: "50%" }}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
          <Address
            getAddress={getAddressHandler}
            placeHolder={
              "Enter Job location address and select from the drop down & current location"
            }
          />
          <Typography
            component={"span"}
            sx={{ color: "red", fontSize: 14, mt: 0, mb: 4, p: 0 }}
            color="error"
          >
            {searchAddressError}
          </Typography>
          <Stack direction="column" spacing={2}>
            <TextField
              disabled
              sx={{ width: "100%" }}
              type="search"
              inputRef={address1Input}
              onChange={address1ChangeHandler}
              placeholder="Street Name/Block/Avenue"
              onBlur={address1ChangeHandler}
              error={address1Error}
              helperText={address1Error && " Address 1 is required"}
            />
            <TextField
              disabled
              sx={{ width: "100%" }}
              type="search"
              inputRef={address2Input}
              onChange={address2ChangeHandler}
              placeholder="Apartment/ Street No"
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={cityInput}
              onChange={cityChangeHandler}
              placeholder="City"
              onBlur={cityChangeHandler}
              error={cityError}
              helperText={cityError && "City is required"}
            />

            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={stateInput}
              onChange={stateChangeHandler}
              placeholder="State"
              onBlur={stateChangeHandler}
              error={stateError}
              helperText={stateError && "State is required"}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={zipInput}
              onChange={zipChangeHandler}
              placeholder="Zip Code"
              onBlur={onZipFocusChange}
              error={zipCodeError}
              helperText={zipCodeError && "ZipCode is required"}
            />

            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={countryInput}
              onChange={countryChangeHandler}
              placeholder="Country"
              onBlur={countryChangeHandler}
              error={countryError}
              helperText={countryError && "Country is required"}
            />
          </Stack>
          <Stack
            direction={"row-reverse"}
            spacing={3}
            height={"4rem"}
            sx={{ marginTop: "3rem" }}
          >
            <Button
              variant="contained"
              onClick={onApplyClick}
              disabled={disable}
            >
              Post Job
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(MANAGE_JOBS);
              }}
              disabled={disable}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ mr: 50, width: "100%", height: "38.5rem" }}>
          <TextField
            required
            name="title"
            defaultValue={title}
            placeholder="Job Title"
            label="Job Title"
            type={"text"}
            fullWidth
            onChange={onTitleChange}
            onBlur={onTitleChange}
            error={titleError}
            helperText={
              titleError &&
              "Job Title is required and should be between 5 to 50 characters"
            }
          />

          {/* <TextEditor getDescription={descriptionChangeHandler} /> */}
          <ReactQuill
            onBlur={() => {
              onDescriptionChange(description);
            }}
            theme="snow"
            value={description}
            onChange={onDescriptionChange}
            placeholder="Job Description along with Working Hours & Payment Terms"
            style={{ height: "14rem", marginBottom: "3vw", marginTop: "1vw" }}
          />
          <Typography
            component={"span"}
            sx={{
              color: "#d32f2f",
              fontSize: "0.75rem",
              mt: 0,
              mb: 4,
              p: 0,
              fontWeight: 400,
            }}
            color="error"
          >
            {descriptionError &&
              "Job description is required and should be between 10 to 2000 characters"}
          </Typography>
          <div style={{ display: "inline" }}>
            <NumericFormat
              placeholder="Enter Price"
              label="Enter price"
              customInput={TextField}
              name="price"
              value={price}
              onChange={onPriceChange}
              allowNegative={false}
              decimalScale={0}
              inputProps={{
                maxLength: 3,
              }}
              sx={{ width: "50%" }}
            />

            <Select
              name="priceType"
              value={priceType}
              onChange={onPriceTypeChange}
              sx={{ width: "10%" }}
            >
              <MenuItem value={"perHr"}>$/Hr</MenuItem>
              <MenuItem value={"perJob"}>$/Job</MenuItem>
            </Select>
          </div>
          <Stack direction="column">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en"
            >
              <Stack
                direction={"row"}
                spacing={3}
              >
                <Stack direction={"row"} spacing={2}>
                  <Typography sx={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                    Start Date:
                  </Typography>
                  <DatePicker
                    minDate={moment(new Date())}
                    value={moment(startDate)}
                    format="MM/DD/YYYY"
                    onChange={(date) => {
                      if (date !== null) {
                        date.startOf("day");
                        onStartDateChange(moment(date).toDate());
                      } else {
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0);
                        onStartDateChange(currentDate);
                      }
                    }}
                  />
                </Stack>

                <Stack direction={"row"} spacing={2}>
                  <Typography sx={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                    End Date:
                  </Typography>
                  <DatePicker
                    minDate={moment(startDate)}
                    value={moment(endDate)}
                    format="MM/DD/YYYY"
                    onChange={(date) => {
                      if (date !== null) {
                        date.startOf("day");
                        onEndDateChange(date.toDate());
                      } else {
                        const currentDate = moment(new Date()).startOf("day");
                        onEndDateChange(currentDate.toDate());
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </LocalizationProvider>
          </Stack>

          <Address
            getAddress={getAddressHandler}
            placeHolder={
              "Enter Job location address and select from the drop down & current location"
            }
          />
          <Typography
            component={"span"}
            sx={{ color: "red", fontSize: 14, mt: 0, mb: 4, p: 0 }}
            color="error"
          >
            {searchAddressError}
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled
              sx={{ width: "100%" }}
              type="search"
              inputRef={address1Input}
              onChange={address1ChangeHandler}
              placeholder="Street Name/Block/Avenue"
              onBlur={address1ChangeHandler}
              error={address1Error}
              helperText={address1Error && " Address 1 is required"}
            />
            <TextField
              disabled
              sx={{ width: "100%" }}
              type="search"
              inputRef={address2Input}
              onChange={address2ChangeHandler}
              placeholder="Apartment/ Street No"
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={cityInput}
              onChange={cityChangeHandler}
              placeholder="City"
              onBlur={cityChangeHandler}
              error={cityError}
              helperText={cityError && "City is required"}
            />

            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={stateInput}
              onChange={stateChangeHandler}
              placeholder="State"
              onBlur={stateChangeHandler}
              error={stateError}
              helperText={stateError && "State is required"}
            />
            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={zipInput}
              onChange={zipChangeHandler}
              placeholder="Zip Code"
              onBlur={onZipFocusChange}
              error={zipCodeError}
              helperText={zipCodeError && "ZipCode is required"}
            />

            <TextField
              type="search"
              disabled
              sx={{ width: "100%" }}
              inputRef={countryInput}
              onChange={countryChangeHandler}
              placeholder="Country"
              onBlur={countryChangeHandler}
              error={countryError}
              helperText={countryError && "Country is required"}
            />
          </Stack>
          <Stack direction={"row-reverse"} spacing={3} justifyContent={"right"}>
            <Button
              variant="contained"
              onClick={onApplyClick}
              disabled={disable}
            >
              Post Job
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(MANAGE_JOBS);
              }}
              disabled={disable}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>

      )}
    </>
  );
};

export default NewJobForm;
