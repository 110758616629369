import React from "react";
import { useDropzone } from "react-dropzone";
import { Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";

type DropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  selected: File[];
  errors: string | null;
  type: string | null;
};

const RESUME = "resume";
const PROFILEIMAGE = "profileImage";
const WORKS = "works";

const Dropzone: React.FC<DropzoneProps> = ({
  onDrop,
  selected,
  errors,
  type,
}) => {
  const isProfileImages = type === PROFILEIMAGE;
  const isResume = type === RESUME;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 2 * 1024 * 1024,
    multiple: false,
  });

  const containerStyles: CSSProperties = {
    border: "1px solid #c4c4c4",
    display: "flex",
    justifyContent: isResume ? "center" : "space-evenly",
    alignItems: "center",
    width: isProfileImages ? "7rem" : "100%",
    height: isProfileImages ? "7rem" : "5rem",
    borderRadius: isProfileImages ? "50%" : "0",
    position: "relative",
  };

  return (
    <div>
      <div {...getRootProps()} style={containerStyles}>
        <input {...getInputProps()} multiple />
        {selected.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {type === PROFILEIMAGE ? (
              <PersonIcon style={{ width: "7rem", height: "7rem" }} />
            ) : type === RESUME ? (
              <Stack direction="row" spacing={1}>
                <Typography>Upload resume</Typography>
                <FileUploadRoundedIcon />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                <Typography>Upload images</Typography>
                <FileUploadRoundedIcon />
              </Stack>
            )}
          </div>
        )}
        {selected.map((file, index) => (
          <div
            key={index}
            style={{
              width: type === WORKS ? "auto" : "100%",
              height: "100%",
            }}
          >
            {type === RESUME && (
              <div
                style={{
                  backgroundColor: "#9a81dc",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <Typography
                  style={{
                    marginLeft: "10px",
                    fontSize: "1.5rem",
                    fontWeight: "500",
                  }}
                >
                  {file.name}
                </Typography>
              </div>
            )}
            {type === WORKS && (
              <img
                src={URL.createObjectURL(file)}
                alt={`Selected Work ${index}`}
                style={{
                  width: "5rem",
                  height: "5rem",
                  borderRadius: "0",
                  display:
                    selected.length > 0 && type === WORKS ? "block" : "none",
                }}
              />
            )}
            {type === PROFILEIMAGE && (
              <img
                src={URL.createObjectURL(file)}
                alt={`Selected Profile Image ${index}`}
                style={{
                  width: "7rem",
                  height: "7rem",
                  borderRadius: "50%",
                  display:
                    selected.length > 0 && type === PROFILEIMAGE
                      ? "block"
                      : "none",
                }}
              />
            )}
          </div>
        ))}
      </div>
      <Typography>{errors}</Typography>
    </div>
  );
};

export default Dropzone;
