import TeenProfileForm from "./TeenProfileForm";
import TeenProfileDTO from "../services/teenagerProfile/TeenProfile.dto";
import teenagerProfileService from "../services/teenagerProfile/teenagerProfile.service";
import { useDispatch, useSelector } from "react-redux";
import { setTeens } from "../redux/features/teenSlice";
import { currentUser } from "../redux/features/userSlice";
import type AppUser from "../services/auth/AppUser";
import { useNavigate } from "react-router-dom";
import { JOB_SEARCH, PROFILE } from "../lib/constants/navigationUrls";
import { useEffect, useState } from "react";
import { USER_ID } from "../lib/constants/localstorageitem";

function TeenProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const [teenProfile, setTeenProfile] = useState<TeenProfileDTO>();
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem(USER_ID) as string;

  const onSubmitHandle = async (details: TeenProfileDTO) => {
    const result = await teenagerProfileService.update(details, userId);
    dispatch(setTeens(details));
    navigate(JOB_SEARCH);
  };

  useEffect(() => {
    void (async () => {
      const res = await teenagerProfileService.get(userId);
      setLoading(false);
      if (Object.keys(res).length > 0) {
        setTeenProfile(res);
        dispatch(setTeens(res));
        navigate(JOB_SEARCH);
      }
    })();
  }, []);

  return (
    <div>
      <>
        <TeenProfileForm
          teen={new TeenProfileDTO(teenProfile)}
          onSubmit={onSubmitHandle}
          loading={loading}
        />
      </>
    </div>
  );
}

export default TeenProfile;
