import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Trravo from "../../assets/trravo.png";
import { USER_ID } from "../../lib/constants/localstorageitem";
import employerService from "../../services/employer/employer.service";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../lib/constants/navigationUrls";
import { useState } from "react";

function EmployerParent() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createAccount = async () => {
    const userId = localStorage.getItem(USER_ID);

    const res = await employerService.HybridUser(userId as string);
    setIsLoading(true);
    setTimeout(() => {
      navigate(PROFILE);
      window.location.reload();
    }, 2000);
  };
  return (
    <>
      <div
        style={{
          marginTop: "7rem",
        }}
      >
        {isLoading && <CircularProgress size={40} />}
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "450px",
            padding: "1rem",
            margin: "1rem",
          }}
        >
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={Trravo}
              alt="trravo"
              style={{ width: "100%", height: "4rem", marginBottom: "1rem" }}
            />

            <Typography
              sx={{
                marginTop: "2rem",
                marginBottom: "1rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Please proceed by clicking the "Create" button to also become an
              Parent.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                onClick={createAccount}
                variant="contained"
                sx={{ marginBottom: "1rem" }}
              >
                Create
              </Button>
            </Box>
          </Stack>
        </Card>
      </div>
    </>
  );
}

export default EmployerParent;
