import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { type JobSearchQueryDTO } from "../../../services/job/dtos/jobSearchQuery.dto";
import { validateZipCode, validateName } from "../../../lib/util/common/utils";

const searchBtn = {
  width: "10rem",
  height: "4rem",
};

interface JobFilterProps {
  onSearch: (data: JobSearchQueryDTO) => void;
 
}
const TeenJobFilter = ({ onSearch }: JobFilterProps) => {
  const futureDate = new Date();
  // Add 3 months to the current date
  futureDate.setMonth(futureDate.getMonth() + 3);
  const [searchKey, setSearchKey] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [titleError, setTitleError] = useState("");
  const [zipError, setZipError] = useState("");



  const onSearchClick = () => {
    validateTitle(searchKey);
    validateZip(zip);

    if (!titleError || !zipError) {
      if (zip.length === 5 || zip === "") {
        if (validateName(searchKey) != null || searchKey === "") {
          onSearch({
            title: searchKey,
            zipCode: zip,
          });
        }
      }
    } else if (titleError) {
      setZipError("");
    } else if (zipError) {
      setTitleError("");
    }
  };

  const handleZipChange = (e: any) => {
    setZipError("");
    let value = e.target.value;
    value = value.replace(/[^\u0030-\u0039]/g, "");
    if (value.length <= 5) {
      setZip(value);
      return true;
    } else {
      setZipError("");
      return false;
    }
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    setSearchKey(title);
    setTitleError("");
  };

  const validateTitle = (title: any) => {
    if (title !== "") {
      if (validateName(title) == null) {
        setTitleError("Numbers and special characters are not allowed");
        return false;
      }
    }
    setTitleError("");
    return true;
  };

  const validateZip = (zip: any) => {
    if (zip !== "") {
      if (zip.length !== 5) {
        setZipError("Zip code should be five digits only");
        return false;
      }
    }
    setZipError("");
    return true;
  };

  return (
    <Grid
      container
      direction={"row"}
      sx={{ px: 10, m: "30px" }}
      spacing={{ xs: 2, md: 3 }}
    >
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          id="search-bar"
          placeholder="Enter Job Title or Keyword"
          size="small"
          name="searchKey"
          value={searchKey}
          onChange={handleTitleChange}
          error={!!titleError}
          helperText={titleError}
          inputProps={{
            style: {
              height: "50px",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          sx={{
            borderRadius: 2,
            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
            '& input[type="number"]': {
              "-moz-appearance": "textfield",
            },
          }}
          id="search-bar"
          placeholder="Enter Zip Code"
          size="small"
          fullWidth
          name="zip"
          value={zip}
          onChange={handleZipChange}
          error={!!zipError}
          helperText={zipError}
          inputProps={{
            style: {
              height: "50px",
            },
            maxLength: 5,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          sx={searchBtn}
          variant="contained"
          onClick={onSearchClick}
          disabled={zipError !== "" || titleError !== ""}
        >
          search
        </Button>
      </Grid>
    </Grid>
  );
};
export default TeenJobFilter;
