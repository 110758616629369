const AppConsts = {
  appBaseUrl: process.env.REACT_APP_BASE_URL || "",
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL || "",
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENTID || "",
  auth0Audience: process.env.REACT_APP_AUTH0_IDENTIFIER || "",
  googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  auth0DbConnection: process.env.REACT_APP_AUTH0_DB_CONNECTION || "",
};

export default AppConsts;
