class ApplicationDTO {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  summary: string;
  price: string;
  priceType: string;
  jobId?: number;

  constructor(data: any = {}) {
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.email = data.email ?? "";
    this.contactNumber = data.contactNumber ?? "";
    this.summary = data.summary ?? "";
    this.price = data.price ?? "";
    this.priceType = data.priceType ?? "perHr";
    this.jobId = data.jobId ?? null;
  }
}
export default ApplicationDTO;
