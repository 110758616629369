import http from "../httpService";
import type JobDTO from "./dtos/job.dto";
import type { JobSearchQueryDTO } from "./dtos/jobSearchQuery.dto";
import queryString from "query-string";
import type AddressDTO from "../address/AddressDTO";

class JobService {
  readonly serviceURL = `job`;

  // Update teenager by ID
  public async create(id:string,job: JobDTO): Promise<JobDTO> {
    const result = await http.post(`${this.serviceURL}/${id}`, job);
    return result.data as JobDTO;
  }

  // Update Job
  public async update(job: JobDTO): Promise<JobDTO> {
    const result = await http.patch(`${this.serviceURL}/${job.id}`, job);
    return result.data as JobDTO;
  }

  // Delete Job
  public async delete(job: JobDTO): Promise<JobDTO> {
    const result = await http.patch(`${this.serviceURL}/delete/${job.id}`);
    return result.data as JobDTO;
  }

  // Get all jobs information with filters
  public async search(
    params?: JobSearchQueryDTO | AddressDTO
  ): Promise<JobDTO[]> {
    const result = await http.get(
      `${this.serviceURL}?${queryString.stringify(params ?? {})}`
    );
    return result.data as JobDTO[];
  }

  // Get Teen Job  data based on id
  public async applicants(id:string | undefined ): Promise<JobDTO> {
    const result = await http.get(`${this.serviceURL}/applications/${id}`);
    return result.data as JobDTO;
  }

  // Get Jobs based on Id
  public async getJobDetails(id: string | undefined): Promise<JobDTO> {
    const result = await http.get(`${this.serviceURL}/${id}`);
    return result.data as JobDTO;
  }
}

export default new JobService();
