import ChildInformation from "./ChildInformationForm";
import ChildInformationDTO from "./Child.dto";
import AppUser from "../services/auth/AppUser";
import { currentUser } from "../redux/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import childProfileService from "../services/childProfile/childProfile.service";
import { addTeen } from "../redux/features/teenSlice";
import { useNavigate } from "react-router-dom";
import { PROFILE, TEEN_APPLIED_JOBS } from "../lib/constants/navigationUrls";
import { useEffect, useState } from "react";
import { USER_ID } from "../lib/constants/localstorageitem";

const ChildInfo = () => {
  const [childProfile, setChildProfile] = useState<ChildInformationDTO>();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = localStorage.getItem(USER_ID) as string;

  const onSubmitChild = async (details: ChildInformationDTO) => {
    const req = await childProfileService.update(details, id);
    dispatch(addTeen(req));
    navigate(TEEN_APPLIED_JOBS);
  };

  useEffect(() => {
    void (async () => {
      const res = await childProfileService.get(id);
      setLoading(false);
      if (Object.keys(res).length > 0) {
        setChildProfile(res);
        dispatch(addTeen(res));
        navigate(TEEN_APPLIED_JOBS);
      }
      
    })();
  }, []);

  return (
    <div>
      <ChildInformation
        child={new ChildInformationDTO(childProfile)}
        onSubmit={onSubmitChild}
        loading={loading}
      />
    </div>
  );
};

export default ChildInfo;
