import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Trravo from "../../assets/trravo.png";
import { USER_ID } from "../../lib/constants/localstorageitem";
import employerService from "../../services/employer/employer.service";
import { useNavigate } from "react-router-dom";
import {
  EMPLOYER_PROFILE,
  MANAGE_JOBS,
  PROFILE,
} from "../../lib/constants/navigationUrls";
import parentService from "../../services/parent/parent.service";
import { useState } from "react";

function ParentEmployer() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const createAccount = async () => {
    const userId = localStorage.getItem(USER_ID);

    const res = await parentService.ParenthybridUser(userId as string);
    setIsLoading(true);
    setTimeout(() => {
      navigate(MANAGE_JOBS);
      window.location.reload();
    }, 2000);
  };
  return (
    <div
      style={{
        marginTop: "7rem",
      }}
    >
      {isLoading && <CircularProgress size={45} />}
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "450px",
          padding: "1rem",
          margin: "1rem",
        }}
      >
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent="space-between"
        >
          <img
            src={Trravo}
            alt="trravo"
            style={{ width: "100%", height: "4rem", marginBottom: "1rem" }}
          />

          <Typography
            sx={{
              marginTop: "2rem",
              marginBottom: "1rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Please proceed by clicking the "Create" button to also become an
            Employer.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              onClick={createAccount}
              variant="contained"
              sx={{ marginBottom: "1rem" }}
            >
              Create
            </Button>
          </Box>
        </Stack>
      </Card>
    </div>
  );
}

export default ParentEmployer;
