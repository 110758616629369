import http from "../httpService";
import type SubscribeRequest from "./dto/SubscribeRequest";
import type Subscription from "./dto/Subscription";

class SubscriptionService {
  // Create subscription request
  public async create(job:SubscribeRequest): Promise<Subscription> {
    const result = await http.post(`/user/subscribe`, job);
    return result.data as Subscription;
  }
}

export default new SubscriptionService();
