import { useParams } from "react-router-dom";
import JobWithApplicants from "./JobWithApplicants";
import type JobDTO from "../../../services/job/dtos/job.dto";
import { useEffect, useState } from "react";
import { getExistedJobs, getManageJobs } from "../../../redux/features/jobSlice";
import jobService from "../../../services/job/job.service";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";

const ManageJobApplicants = () => {
  const job = useSelector(getExistedJobs);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  const getJobDetails = async (job?: JobDTO, type?: string) => {
    setLoading(true);
    const applicants = await jobService.applicants(id);
    const jobDetail = await jobService.getJobDetails(id);
    dispatch(getManageJobs({ job: jobDetail, applicants }));
    setLoading(false);
  };
  useEffect(() => {
    void getJobDetails();
  }, []);
  const editHandler = () => {};

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <JobWithApplicants onApply={editHandler} job={job.job} edit={true} />
      )}
    </>
  );
};

export default ManageJobApplicants;
