import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import trravoLogo from "../../assets/trravo1.png";
import LogoutButton from "../../components/Auth/Logout";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../redux/features/userSlice";

const Header: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  const userNames = useSelector(getUserProfile);

  const { firstName, lastName } = userNames.userProfile;

  const userName = firstName || lastName ? `${firstName} ${lastName}` : null;

  return (
    <AppBar position="relative" sx={{ height: "4.5rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Toolbar>
          <img
            src={trravoLogo}
            alt="trravo1"
            style={{ height: "4rem", width: "10rem" }}
          />
        </Toolbar>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          style={{ marginRight: "1rem" }}
        >
          {isAuthenticated ? <Typography>{userName}</Typography> : ""}
          <LogoutButton />
        </Stack>
      </Box>
    </AppBar>
  );
};

export default Header;
