import ChildInformationDTO from "../../scenes/Child.dto";
import http from "../httpService";
import type ChildProfileDTO from "./childProfile.dto";

class ChildProfileService {
  public async update(
    details: ChildInformationDTO,
    id: string
  ): Promise<ChildInformationDTO> {
    const result = await http.post(`parent/teenager/${id}`, details);
    return result.data as ChildInformationDTO;
  }

  public async get(id: string): Promise<ChildInformationDTO> {
    const result = await http.get(`/user/profile2/${id}`);
    return result.data as ChildInformationDTO;
  }
}

export default new ChildProfileService();
