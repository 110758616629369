import {
  TextField,
  Card,
  Stack,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import type ChildInformationDTO from "./Child.dto";
import { validateEmail, validateEmailID, validateFirstName, validateLastName, validateName } from "../lib/util/common/utils";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "./public/Header";
import { PROFILE } from "../lib/constants/navigationUrls";
import Loader from "../components/Loader";

interface ChildProps {
  child: ChildInformationDTO;
  onSubmit: (child: ChildInformationDTO) => void;
  loading: boolean;
}

const FIRSTNAME = "firstName";
const LASTNAME = "lastName";
const EMAIL = "email";
const DESCRIPTION = "description";

function ChildInformation({ child, onSubmit, loading }: ChildProps) {
  const [captchaError, setCaptchaError] = useState<boolean>(true);
  const [childData, setChildData] = useState(child);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const { firstName, lastName, email, description } = childData;
  const navigate = useNavigate();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    const data: ChildInformationDTO = {
      ...childData,
    };

    switch (target.name) {
      case FIRSTNAME:
        data.firstName = target.value;
        break;
      case LASTNAME:
        data.lastName = target.value;
        break;
      case EMAIL:
        data.email = target.value;
        break;
      case DESCRIPTION:
        data.description = target.value;
        break;
      default:
        break;
    }
    setChildData(data);
  };

  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case FIRSTNAME:
        setNameError(validateFirstName(value));
        break;

      case LASTNAME:
        setLastNameError(validateLastName(value));
        break;
      case EMAIL:
        setEmailError(validateEmail(value));
        break;
      default:
        break;
    }
  };

  const isFormValid = (child: ChildInformationDTO): boolean => {
    const { firstName, email } = childData;

    const userNameError = validateFirstName(firstName);
    setNameError(userNameError);

    const lastNameError = validateLastName(lastName);
    setLastNameError(lastNameError);

    const userEmailError = validateEmail(email);
    setEmailError(userEmailError);

    return (
      userNameError === null &&
      lastNameError === null &&
      userEmailError === null
    );
  };

  const onSubmitClick = (child: ChildInformationDTO): void => {
    if (isFormValid(child)) {
      onSubmit(childData);
    }
  };

  
  const onCaptchaChange = () => {
    setCaptchaError(false);
  };
  

  return (
    <Grid justifyContent="center" container direction="row">
      <Header />
      {loading && <Loader />}
      <Card sx={{ marginTop: "3rem",  marginBottom: "2rem" }}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontFamily: "Impact",
            mb: 2,
            textAlign: "center",
          }}
        >
          Parent Profile
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              width: "80%",
            }}
          >
            Student Information
          </Typography>
        </div>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          margin="0"
        >
          <div style={{ display: "flex", width: "80%" }}>
            <TextField
              label="Enter Child First Name"
              placeholder="Enter  First Name"
              sx={{ width: "80%", mt: "0.6rem", marginRight: "3rem" }}
              name={FIRSTNAME}
              value={firstName}
              onChange={onInputChange}
              onBlur={onInputFocusOut}
              error={nameError !== null}
              helperText={nameError}
              variant="standard"
              required
            />
            <TextField
              label="Enter Child Last Name"
              placeholder="Enter  Last Name"
              sx={{ width: "80%", mt: "0.6rem" }}
              name={LASTNAME}
              value={lastName}
              onChange={onInputChange}
              onBlur={onInputFocusOut}
              error={lastNameError !== null}
              helperText={lastNameError}
              variant="standard"
              required
            />
          </div>
          <TextField
            type="email"
            label="Enter Child Email "
            placeholder="Enter Child Email"
            sx={{ width: "80%", mt: "0.6rem" }}
            name={EMAIL}
            value={email}
            onChange={onInputChange}
            onBlur={onInputFocusOut}
            error={emailError !== null}
            helperText={emailError}
            variant="standard"
            required
          />
          <TextField
            type="text"
            label="Message (optional)"
            placeholder="Enter Message about your Child"
            autoComplete="none"
            inputProps={{ style: { height: "3rem" } }}
            sx={{ width: "80%", mt: "0.6rem" }}
            name={DESCRIPTION}
            value={description}
            onChange={onInputChange}
            variant="outlined"
            multiline
            rows={4}
          />

          <Stack sx={{ marginTop: "1rem" }}>
            <ReCAPTCHA
              sitekey="6LcJwB0nAAAAADZD1z0GPJRC9HqYXDAfQgOsQ3WC"
              onChange={onCaptchaChange}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{ marginTop: "2rem", marginBottom: "1rem" }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSubmitClick(childData);
              }}
              sx={{ marginLeft: "1rem" }}
              disabled={captchaError}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
}

export default ChildInformation;
