import { useState, useRef, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Stack,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  type SelectChangeEvent,
  Select,
  MenuItem,
} from "@mui/material";
import type JobDTO from "../../../services/job/dtos/job.dto";
import AddressDTO from "../../../services/address/AddressDTO";
import Address from "../../../components/Address";
import TextEditor from "../../../components/TextEditor";
import { validateLength, validateZip } from "../../../lib/util/common/utils";
import { useDispatch, useSelector } from "react-redux";
import { getExistedJobs, postJobs } from "../../../redux/features/jobSlice";
import { currentUser } from "../../../redux/features/userSlice";
import "./JobWithApplicants.css";
import jobService from "../../../services/job/job.service";
import ApplicantsList from "./ApplicantsList";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import DateRangeDTO from "../../../services/job/dtos/date.dto";
import NumericFormat from "react-number-format";
import { MANAGE_JOBS } from "../../../lib/constants/navigationUrls";
interface JobFormProps {
  edit?: boolean;
  job: JobDTO;
  onApply: (job: JobDTO) => void;
  onCloseJob?: () => void;
  onEdit?: () => void;
  setOpen?: boolean;
  updateJobs?: Function;
}

const JobWithApplicants = (props: JobFormProps) => {
  const { onApply, edit } = props;

  const [job, setJob] = useState<JobDTO>(props.job);
  const [dates, setDates] = useState<[Date, Date]>([new Date(), new Date()]);
  const [titleError, setTitleError] = useState<boolean>();
  const [priceError, setPriceError] = useState<boolean>();
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [streetError, setStreetError] = useState<boolean>();
  const [cityError, setCityError] = useState<boolean>();
  const [stateError, setStateError] = useState<boolean>();
  const [zipCodeError, setZipCodeError] = useState<boolean>();
  const [countryError, setCountryError] = useState<boolean>();
  const [zipHelperText, setZipHelperText] = useState<string>();
  const [editable, setEditable] = useState(true);
  const streetInput = useRef();
  const cityInput = useRef(null);
  const stateInput = useRef(null);
  const zipInput = useRef(null);
  const countryInput = useRef(null);

  const [disable, setDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(
    props.job !== undefined && props.job.address !== undefined
      ? props.job.address
      : new AddressDTO()
  );

  const [jobAddress, setAddress] = useState<AddressDTO>(new AddressDTO());
  const [open, setOpen] = useState(false);
  const { description, endDate, startDate, title, price, priceType } = job;
  const [hide, setHide] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateError, setDateError] = useState("");

  const teen = useSelector(getExistedJobs);
  const user = useSelector(currentUser);
  useEffect(() => {
    setJob(teen.job);
  }, [teen.job]);

  useEffect(() => {
    if (edit) {
      // @ts-expect-error

      setDates([moment(startDate), moment(endDate)]);
    }
  }, [edit]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onTitleChange = (e: any) => {
    const value = e.target.value;
    if (value.length >= 5) {
      setTitleError(false);
      const edited = { ...job };
      edited.title = value;
      setJob(edited);
    } else {
      setTitleError(true);
    }
  };

  const onPriceChange = (e: any) => {
    let value = e.target.value;
    const edited = { ...job };
    edited.price = value;
    setJob(edited);
  };

  const onPriceTypeChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ): void => {
    const { value } = event.target;
    const edited = { ...job };
    edited.priceType = value;
    setJob(edited);
  };

  const descriptionChangeHandler = (val: any) => {
    const edited = { ...job };
    edited.description = val;

    if (validateLength(val, 10, 6000)) {
      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }

    setJob(edited);
  };

  const onApplyClick = () => {
    const titleError = !title;
    const descriptionError = !description;
    // @ts-expect-error
    const streetError = !streetInput?.current?.value;
    // @ts-expect-error
    const cityError = !cityInput?.current?.value;
    // @ts-expect-error
    const stateError = !stateInput?.current?.value;
    // @ts-expect-error
    const zipCodeError = !zipInput?.current?.value;
    // @ts-expect-error
    const countryError = !countryInput?.current?.value;

    setTitleError(titleError);
    setDescriptionError(descriptionError);
    setStreetError(streetError);
    setCityError(cityError);
    setStateError(stateError);
    setZipCodeError(zipCodeError);
    setCountryError(countryError);

    if (
      titleError ||
      descriptionError ||
      streetError ||
      cityError ||
      stateError ||
      zipCodeError ||
      countryError
    ) {
      return;
    }
    const dateRange = new DateRangeDTO({
      startDate:
        startDate != null
          ? moment(startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      endDate:
        endDate != null
          ? moment(endDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
    });

    const edited = { ...job, dateRange, employerId: user.id };
    onApply(edited);
    dispatch(postJobs({ edited }));
    setDisabled(true);
  };

  const onStartDateChange = (val: Date) => {
    if (moment(val) > moment(endDate)) {
      setJob({ ...job, startDate: val, endDate: val });
    } else {
      setJob({ ...job, startDate: val });
    }
  };

  const onEndDateChange = (val: Date) => {
    setJob({ ...job, endDate: val });
  };

  const getAddressHandler = (address: AddressDTO) => {
    setAddress(address);
    const edited = { ...job };
    if (Object.keys(address).length !== 0) {
      edited.address = address;
      setJob(edited);
      setStreetError(false);
      setCityError(false);
      setStateError(false);
      setCountryError(false);

      if (!address.zipCode) {
        setZipCodeError(true);
      } else {
        setZipCodeError(false);
      }

      // @ts-expect-error
      streetInput.current.value = `${address.address1} ${address.address2}`;
      // @ts-expect-error
      cityInput.current.value = address.city;
      // @ts-expect-error
      stateInput.current.value = address.state;
      // @ts-expect-error
      zipInput.current.value = address.zipCode;
      // @ts-expect-error
      countryInput.current.value = address.country;
    }
  };

  const streetChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setStreetError(false);
      // @ts-expect-error
      streetInput.current.value = value;
    } else {
      setStreetError(true);
    }
  };
  const cityChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setCityError(false);
      // @ts-expect-error
      cityInput.current.value = value;
    } else {
      setCityError(true);
    }
  };
  const stateChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setStateError(false);
      // @ts-expect-error
      stateInput.current.value = value;
    } else {
      setStateError(true);
    }
  };
  const onZipFocusChange = (e: any) => {
    const value = e.target.value;
    if (value.length === 0) {
      setZipCodeError(true);
      setZipHelperText("Zip Code is required");
    } else {
      setZipCodeError(false);
    }
  };

  const zipChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length === 5) {
      setZipCodeError(false);
    } else if (value.length === 9) {
      setZipCodeError(false);
      // @ts-expect-error
      zipInput.current.value = validateZip(value);
    } else {
      setZipCodeError(true);
      setZipHelperText("Please enter a valid zipcode");
    }
  };
  const countryChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 0) {
      setCountryError(false);
      // @ts-expect-error
      countryInput.current.value = value;
    } else {
      setCountryError(true);
    }
  };
  const editHandler = () => {
    // ts-ignore
    // /updateJobs(job)
    setEditable(false);
    setHide(true);
  };

  const updateJobHandler = async (job: JobDTO) => {
    if (
      moment(startDate) <= moment(endDate) &&
      moment(new Date()) <= moment(endDate)
    ) {
      const edited = {
        ...job,
        startDate,
        endDate,
      };
      await jobService.update(edited);
      setSnackbarOpen(true);
      setEditable(true);
    } else {
      setDateError("Choose Valid dates ");
    }
  };

  const deleteJob = async (job: JobDTO) => {
    await jobService.delete(job);
    setTimeout(() => {
      navigate(MANAGE_JOBS);
    }, 1000);
  };

  return (
    <>
      <Stack spacing={2} sx={{ mr: 50, width: "100%", height: "38.5rem" }}>
        {edit ? (
          <>
            <TextField
              name="title"
              defaultValue={title}
              placeholder="Job Title"
              type={"text"}
              fullWidth
              variant="standard"
              onChange={onTitleChange}
              onBlur={onTitleChange}
              error={titleError}
              helperText={
                titleError &&
                "Job Title is required and should be between 5 to 50 characters"
              }
              disabled={editable}
            />

            <TextEditor
              description={description}
              getDescription={descriptionChangeHandler}
              edit={editable}
            />
            <Typography
              component={"span"}
              sx={{ color: "#c20404", fontSize: 14, mt: 0, mb: 4, p: 0 }}
              color="error"
            >
              {descriptionError &&
                "Job description is required and should be between 10 to 2000 characters"}
            </Typography>
            <div style={{ display: "inline" }}>
              <NumericFormat
                placeholder="Enter Price"
                customInput={TextField}
                variant="standard"
                name="price"
                defaultValue={price}
                onChange={onPriceChange}
                error={priceError}
                helperText={priceError}
                disabled={editable}
                allowNegative={false}
                decimalScale={0}
                inputProps={{
                  maxLength: 3,
                }}
              />

              <Select
                name="priceType"
                value={priceType}
                onChange={onPriceTypeChange}
                sx={{ width: "10%" }}
                variant="standard"
                disabled={editable}
              >
                <MenuItem value="perHr">$/Hr</MenuItem>
                <MenuItem value="perJob">$/Job</MenuItem>
              </Select>
            </div>
            <Stack direction="row" spacing={3} alignItems="center">
              <Stack direction="column" sx={{ marginTop: "15px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en"
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ marginTop: "1.5rem" }}
                  >
                    <DatePicker
                      label="Start Date"
                      disabled={editable}
                      minDate={moment(new Date())}
                      value={moment.parseZone(startDate)}
                      format="MM/DD/YYYY"
                      onChange={(date) => {
                        if (date !== null) {
                          date.startOf("day");
                          onStartDateChange(moment(date).toDate());
                        } else {
                          const currentDate = new Date();
                          currentDate.setHours(0, 0, 0, 0);
                          onStartDateChange(currentDate);
                        }
                      }}
                      timezone="system"
                    />

                    <DatePicker
                      label="End Date"
                      disabled={editable}
                      // minDate={moment.parseZone(startDate) < moment().startOf('day') ? moment().startOf('day') : moment.parseZone(endDate)}
                      minDate={
                        moment(new Date()) < moment(startDate)
                          ? moment(startDate)
                          : moment(new Date())
                      }
                      // value={moment.parseZone(endDate) < moment().startOf('day') ? moment().startOf('day') : moment.parseZone(endDate)}
                      format="MM/DD/YYYY"
                      value={moment.parseZone(endDate)}
                      onChange={(date) => {
                        if (date !== null) {
                          date.startOf("day");
                          onEndDateChange(date.toDate());
                        } else {
                          const currentDate = moment(new Date()).startOf("day");
                          onEndDateChange(currentDate.toDate());
                        }
                      }}
                      slotProps={{
                        textField: {
                          helperText: dateError,
                        },
                      }}
                      timezone="system"
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>
            </Stack>
            <Address
              getAddress={getAddressHandler}
              placeHolder={
                "Enter Job location address and select from the drop down & current location"
              }
              edit={hide}
            />

            <TextField
              type="search"
              value={`${currentAddress?.address1} ${currentAddress?.address2}`}
              inputRef={streetInput}
              onChange={streetChangeHandler}
              placeholder="Street Name/Block/Avenue"
              variant="standard"
              onBlur={streetChangeHandler}
              error={streetError}
              helperText={streetError && "Street Address is required"}
              disabled={hide}
            />

            <Stack direction="row" spacing={2}>
              <TextField
                value={currentAddress?.city}
                type="search"
                inputRef={cityInput}
                onChange={cityChangeHandler}
                placeholder="City"
                variant="standard"
                onBlur={cityChangeHandler}
                error={cityError}
                helperText={cityError && "City is required"}
                disabled={hide}
              />

              <TextField
                type="search"
                value={currentAddress?.state}
                inputRef={stateInput}
                onChange={stateChangeHandler}
                placeholder="State"
                variant="standard"
                onBlur={stateChangeHandler}
                error={stateError}
                helperText={stateError && "State is required"}
                disabled={hide}
              />

              <TextField
                type="search"
                value={currentAddress?.zipCode}
                inputRef={zipInput}
                onChange={zipChangeHandler}
                placeholder="Zip Code"
                variant="standard"
                onBlur={onZipFocusChange}
                error={zipCodeError}
                helperText={zipCodeError && zipHelperText}
                disabled={hide}
              />

              <TextField
                type="search"
                value={currentAddress?.country}
                inputRef={countryInput}
                onChange={countryChangeHandler}
                placeholder="Country"
                variant="standard"
                onBlur={countryChangeHandler}
                error={countryError}
                helperText={countryError && "Country is required"}
                disabled={hide}
              />
            </Stack>
          </>
        ) : (
          <>
            <TextField
              name="title"
              value={title}
              placeholder="Job Title"
              type={"text"}
              fullWidth
              variant="standard"
              onChange={onTitleChange}
              onBlur={onTitleChange}
              error={titleError}
              helperText={titleError && "Job Title is required"}
            />

            <TextEditor getDescription={descriptionChangeHandler} />

            <Address
              getAddress={getAddressHandler}
              placeHolder={"Job Location"}
            />

            <TextField
              type="search"
              disabled
              inputRef={streetInput}
              onChange={streetChangeHandler}
              placeholder="Street Name/Block/Avenue"
              variant="standard"
              onBlur={streetChangeHandler}
              error={streetError}
              helperText={streetError && "Street Address is required"}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                disabled
                type="search"
                inputRef={cityInput}
                onChange={cityChangeHandler}
                placeholder="City"
                variant="standard"
                onBlur={cityChangeHandler}
                error={cityError}
                helperText={cityError && "City is required"}
              />

              <TextField
                disabled
                type="search"
                inputRef={stateInput}
                onChange={stateChangeHandler}
                placeholder="State"
                variant="standard"
                onBlur={stateChangeHandler}
                error={stateError}
                helperText={stateError && "State is required"}
              />
              <TextField
                disabled
                type="search"
                inputRef={zipInput}
                onChange={zipChangeHandler}
                placeholder="Zip Code"
                variant="standard"
                onBlur={onZipFocusChange}
                error={zipCodeError}
                helperText={zipCodeError && zipHelperText}
              />

              <TextField
                disabled
                type="search"
                inputRef={countryInput}
                onChange={countryChangeHandler}
                placeholder="Country"
                variant="standard"
                onBlur={countryChangeHandler}
                error={countryError}
                helperText={countryError && "Country is required"}
              />
            </Stack>
          </>
        )}

        <>
          <Stack
            direction="row-reverse"
            spacing={5}
            justifyContent="center"
            alignContent="center"
          >
            <Button
              onClick={() => {
                navigate(MANAGE_JOBS);
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: "3rem" }}
              onClick={handleClickOpen}
              variant="contained"
            >
              Delete
            </Button>
            {editable ? (
              <Button variant="contained" onClick={editHandler}>
                Edit
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  void updateJobHandler(job);
                }}
                disabled={disable || descriptionError}
              >
                Save
              </Button>
            )}
          </Stack>
        </>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "4rem",
          }}
        >
          {edit ? (
            <>
              <Stack direction="column" sx={{ marginBottom: "3rem" }}>
                <Stack
                  direction="row"
                  justifyContent="right"
                  sx={{ marginRight: "2rem" }}
                >
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ fontWeight: "bold" }}>
                          Are you sure you want to Delete this job
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ marginRight: "2rem" }}
                        color="success"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          void deleteJob(job);
                        }}
                        autoFocus
                        variant="contained"
                        color="error"
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Stack>
              </Stack>
            </>
          ) : (
            <Button
              variant="contained"
              type={"submit"}
              onClick={onApplyClick}
              disabled={disable}
            >
              Post Job
            </Button>
          )}

          <Snackbar
            open={snackbarOpen}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            autoHideDuration={4000}
            onClose={() => {
              setSnackbarOpen(false);
            }}
            message="Job Applied"
          >
            <Alert
              onClose={() => {
                setSnackbarOpen(false);
              }}
              severity="success"
              sx={{ width: "100%", fontWeight: "bold" }}
            >
              Job Updated successfully.
            </Alert>
          </Snackbar>
        </div>
        <div>
          {edit && (
            <Stack sx={{ alignItems: "center" }}>
              <ApplicantsList />
            </Stack>
          )}
        </div>
      </Stack>
    </>
  );
};

export default JobWithApplicants;
