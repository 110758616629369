class User {
  id: number | null;
  email: string;
  contactNumber: string;
  auth0Id: string;
  role: string;
  currentRole?: number;

  constructor(_data: Partial<User> = {}) {
    this.id = _data.id ?? null;
    this.email = _data.email ?? "";
    this.auth0Id = _data.auth0Id ?? "";
    this.contactNumber = _data.contactNumber ?? "";
    this.role = _data.role ?? "";
  }
}

export default User;
