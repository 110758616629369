class ProfileDTO {
  contactNumber: string;
  email?: string;
  firstName: string;
  lastName: string;
  zipCode: string;

  constructor(data: Partial<ProfileDTO> = {}) {
    this.contactNumber = data.contactNumber ?? "";
    this.email = data.email ?? "";
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.zipCode = data.zipCode ?? "";
  }
}
export default ProfileDTO;
