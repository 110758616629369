import React, { useEffect, createContext, type ReactNode } from "react";
import Analytics from "@analytics/core";
import segmentPlugin from "@analytics/segment";

interface MainProps {
  children: ReactNode;
}

const AnalyticsContext = createContext<any>(null);

const analytics = Analytics({
  app: "my-app-name",
  plugins: [
    segmentPlugin({
      writeKey: "RMEf9NSCAsrXmma0QcOvKAx8hrI61BxH",
    }),
  ],
});

function Main({ children }: MainProps) {
  useEffect(() => {
    analytics.page();
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export default Main;
export { AnalyticsContext };
