import { TextField, Card, Stack, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  validateDescription,
  validateEmail,
  validateFirstName,
  validateInterest,
  validateLastName,
} from "../lib/util/common/utils";
import { useNavigate } from "react-router-dom";
import type TeenProfileDTO from "../services/teenagerProfile/TeenProfile.dto";
import Header from "./public/Header";
import { PROFILE } from "../lib/constants/navigationUrls";
import { useDropzone } from "react-dropzone";
import Loader from "../components/Loader";
import Dropzone from "../components/Dropzone";

interface ChildProps {
  teen: TeenProfileDTO;
  onSubmit: (teen: TeenProfileDTO) => void;
  loading: boolean;
}

const FIRSTNAME = "firstName";
const LASTNAME = "lastName";
const EMAIL = "email";
const DESCRIPTION = "description";
const INTERESTS = "interests";
const RESUME = "resume";
const WORKS = "works";

function TeenProfileForm({ teen, onSubmit, loading }: ChildProps) {
  const [teenData, setTeenData] = useState(teen);
  const { firstName, lastName, email, description, interests } = teenData;
  const [nameError, setNameError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [interestError, setInterestError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [resumeFileName, setResumeFileName] = useState<string | null>(null);
  const [worksPreviewURL, setWorksPreviewURL] = useState<string | null>(null);
  const [errorWorks, setErrorWorks] = useState<string | null>(null);
  const [errorResume, setErrorResume] = useState<string | null>(null);
  const [selectedWorks, setSelectedWorks] = useState<File[]>([]);

  const [selectedResume, setSelectedResume] = useState<File[]>([]);
  const [disable, setDisabled] = useState(false)
  const navigate = useNavigate();

  const onInputChange = (event: any): void => {
    const { target } = event;
    const data: TeenProfileDTO = {
      ...teenData,
    };

    switch (target.name) {
      case FIRSTNAME:
        data.firstName = target.value;
        break;
      case LASTNAME:
        data.lastName = target.value;
        break;
      case EMAIL:
        data.email = target.value;
        break;
      case DESCRIPTION:
        data.description = target.value;
        break;
      case INTERESTS:
        data.interests = target.value;
        break;
      case RESUME:
        data.resume = target.files?.[0] || "";
        setResumeFileName(target.files?.[0]?.name || null);

        break;
      case WORKS:
        data.works = target.files?.[0] || "";

        if (target.files?.[0]) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setWorksPreviewURL(reader.result as string);
          };
          reader.readAsDataURL(target.files[0]);
        } else {
          setWorksPreviewURL(null);
        }
        break;
      default:
        break;
    }
    setTeenData(data);
  };

  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case FIRSTNAME:
        setNameError(validateFirstName(value));
        break;

      case LASTNAME:
        setLastNameError(validateLastName(value));
        break;
      case EMAIL:
        setEmailError(validateEmail(value));
        break;
      case DESCRIPTION:
        setDescriptionError(validateDescription(value));
        break;
      case INTERESTS:
        setInterestError(validateInterest(value));
        break;
      default:
        break;
    }
    if (
      (name === FIRSTNAME || name === LASTNAME || name === EMAIL) &&
      value.trim() !== ""
    ) {
      if (teenData.firstName.trim() === "")
        setNameError("First Name is required");
      if (teenData.lastName.trim() === "")
        setLastNameError("Last Name is required");
      if (teenData.email.trim() === "") setEmailError("Email is required");
    }
  };
  useEffect(() => {
    setTeenData(teen);
  }, [teen]);

  const isFormValid = (teen: TeenProfileDTO): boolean => {
    const { firstName, lastName, email, description, interests } = teenData;
  
    const userDescriptionError = validateDescription(description);
    setDescriptionError(userDescriptionError);
  
    const userInterestError = validateInterest(interests);
    setInterestError(userInterestError);
  
   
  
    return  userDescriptionError === null && userInterestError === null ;
  };
  

  const onSubmitClick = async (teen: TeenProfileDTO): Promise<void> => {
    if (isFormValid(teen)) {
      onSubmit(teenData);
      setDisabled(true)
    }
  };
  
  const onDropWorks = (acceptedFiles: any) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxFileSize = 2 * 1024 * 1024;

    const validFiles = acceptedFiles.filter(
      (file: any) =>
        allowedTypes.includes(file.type) && file.size <= maxFileSize
    );

    const oversizedFiles = acceptedFiles.filter(
      (file: any) => file.size > maxFileSize
    );
    const invalidTypeFiles = acceptedFiles.filter(
      (file: any) => !allowedTypes.includes(file.type)
    );

    if (invalidTypeFiles.length > 0 && oversizedFiles.length > 0) {
      setErrorWorks(
        "Please select only JPEG, JPG, or PNG images that are up to 2 MB in size."
      );
    } else if (invalidTypeFiles.length > 0) {
      setErrorWorks("Please select only JPEG, JPG, or PNG images.");
    } else if (oversizedFiles.length > 0) {
      setErrorWorks("Each image should be up to 2 MB in size.");
    } else {
      const updatedSelectedWorks = [...selectedWorks, ...validFiles];

      if (updatedSelectedWorks.length <= 3) {
        setSelectedWorks(updatedSelectedWorks);
        setErrorWorks("");

        const data: TeenProfileDTO = {
          ...teenData,
          works: updatedSelectedWorks,
        };
        setTeenData(data);
      } else {
        setErrorWorks("You can only select up to 3 images.");
      }
    }
  };

  const { getRootProps: getWorksRootProps, getInputProps: getWorksInputProps } =
    useDropzone({
      onDrop: onDropWorks,
      maxSize: 2 * 1024 * 1024,
    });

  const onDropResume = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const maxFileSize = 2 * 1024 * 1024;
    if (file.type === "application/pdf") {
      if (file.size <= maxFileSize) {
        const data: TeenProfileDTO = {
          ...teenData,
          resume: file,
        };
        setTeenData(data);
        setResumeFileName(file.name);
        setSelectedResume([file]);
        setErrorResume("");
      } else {
        setErrorResume("Please select a resume file up to 2MB size.");
      }
    } else {
      setErrorResume("Please select PDF format only");
    }
  };

  const {
    getRootProps: getResumeRootProps,
    getInputProps: getResumeInputProps,
  } = useDropzone({
    onDrop: onDropResume,
    maxSize: 2 * 1024 * 1024,
  });

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            width: "100%",
            maxWidth: "600px",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontFamily: "Impact",
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Student Profile
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                width: "70%",
              }}
            >
              Parent Information
            </Typography>
          </div>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <div style={{ display: "flex", width: "70%" }}>
              <TextField
                label="Enter Parent First Name"
                placeholder="Enter First Name"
                sx={{ marginTop: "1rem", marginRight: "3rem" }}
                name={FIRSTNAME}
                value={firstName}
                onChange={onInputChange}
                onBlur={onInputFocusOut}
                error={nameError !== null}
                helperText={nameError}
                variant="standard"
              />
              <TextField
                label="Enter Parent Last Name"
                placeholder="Enter Last Name"
                sx={{ marginTop: "1rem" }}
                name={LASTNAME}
                value={lastName}
                onChange={onInputChange}
                onBlur={onInputFocusOut}
                error={lastNameError !== null}
                helperText={lastNameError}
                variant="standard"
              />
            </div>

            <TextField
              type="email"
              label="Enter Parent Email"
              placeholder="Enter Parent Email"
              sx={{ marginTop: "1rem", width: "70%" }}
              name={EMAIL}
              value={email}
              onChange={onInputChange}
              onBlur={onInputFocusOut}
              error={emailError !== null}
              helperText={emailError}
              variant="standard"
            />

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                width: "70%",
                marginTop: "2rem",
              }}
            >
              JobSeeker Additional Details{" "}
            </Typography>
            <TextField
              type="text"
              label="About Yourself"
              placeholder="Enter About Yourself"
              autoComplete="none"
              inputProps={{ style: { height: "3rem" } }}
              sx={{ marginTop: "1rem", width: "70%" }}
              name={DESCRIPTION}
              value={description}
              onChange={onInputChange}
              onBlur={onInputFocusOut}
              error={descriptionError !== null}
              helperText={descriptionError}
              variant="outlined"
              multiline
              rows={4}
              required
            />
            <TextField
              type="text"
              label="Area of Interests"
              placeholder="Ex: BabySitter,DogWalker,LawnMover....."
              autoComplete="none"
              inputProps={{ style: { height: "3rem" } }}
              sx={{ marginTop: "1rem", width: "70%" }}
              name={INTERESTS}
              value={interests}
              onChange={onInputChange}
              onBlur={onInputFocusOut}
              error={interestError !== null}
              helperText={interestError}
              variant="outlined"
              multiline
              rows={4}
              required
            />

            {/* <Stack
              direction={"column"}
              sx={{ marginTop: "1rem", width: "70%" }}
            >
              <Typography>Attach Resume</Typography>
              <Dropzone
                onDrop={onDropResume}
                selected={selectedResume}
                errors={errorResume}
                type={RESUME}
              />
            </Stack> */}

            {/* <Stack
              direction={"column"}
              sx={{ marginTop: "1rem", width: "70%" }}
            >
              <Typography>ShowCase Your Work</Typography>
              <Dropzone
                onDrop={onDropWorks}
                selected={selectedWorks}
                errors={errorWorks}
                type={WORKS}
              />
            </Stack> */}

            <Stack
              direction="row"
              sx={{ marginTop: "2rem", marginBottom: "1rem" }}
              spacing={5}
            >
              <Button
                variant="contained"
                onClick={() => onSubmitClick(teenData)}
                disabled={ disable || lastNameError !== null || nameError !== null  || emailError !== null}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Card>
      </div>
    </>
  );
}

export default TeenProfileForm;
