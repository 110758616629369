import {
  Alert,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { Registration } from "../../components/Auth/Registration";
import { RoleIdMapper, Roles } from "../../lib/util/common/registration/utils";
import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "@mui/icons-material/Check";
import Header from "./Header";
import React, { useState, type FC } from "react";
import registrationService from "../../services/registration/registration.service";
import type RegistrationDTO from "../../services/registration/registration.dto";
import RegistrationHeader from "./RegistartionHeader";

const SignUp: FC = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<number>(
    RoleIdMapper[Roles.PARENT]
  );
  const [registrationSuccessful, setRegistrationSuccessful] =
    useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const onSubmit = async (
    firstName: string,
    lastName: string,
    email: string,
    contactNumber: string,
    password: string
  ) => {
    setLoading(true);

    try {
      const body: RegistrationDTO = {
        firstName,
        lastName,
        email,
        password,
        contactNumber,
        role: selectedRoleId,
      };

      await registrationService.register(body);

      setRegistrationSuccessful(true);
      setTimeout(function () {
        void loginWithRedirect();
      }, 3000);
    } catch {
      setRegistrationSuccessful(false);
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  if (registrationSuccessful) {
    return (
      <>
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15rem ",
          }}
        >
          <CheckIcon color="success" sx={{ height: "100px", width: "100px" }} />
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              mb: 1,
              textAlign: "center",
              color: "success",
            }}
          >
            Registration Successful
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "center",
              color: "success",
            }}
          >
            Redirecting to Login Page ...
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <RegistrationHeader />
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        direction="row"
        height="calc(100vh - 72px)"
      >
        <Card>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            height="28rem"
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "center",
                cursor: "pointer",
                width: "90%",
              }}
            >
             Register
            </Typography>

            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "11px",
                width: "95%",
              }}
            >
              <FormLabel color="primary" id="radio-buttons-group-label">
                I am a
              </FormLabel>
              <RadioGroup
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedRoleId(Number(e.target.value));
                }}
                row
                defaultValue={selectedRoleId}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={RoleIdMapper[Roles.PARENT]}
                  control={<Radio />}
                  label={Roles.PARENT}
                />
                <FormControlLabel
                  value={RoleIdMapper[Roles.STUDENT]}
                  control={<Radio />}
                  label={Roles.STUDENT}
                />
                <FormControlLabel
                  value={RoleIdMapper[Roles.EMPLOYER]}
                  control={<Radio />}
                  label={Roles.EMPLOYER}
                />
              </RadioGroup>
            </FormControl>

            <Registration onSubmit={onSubmit} loading={loading} selectedRoleId={selectedRoleId} />
          </Stack>
        </Card>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="error"
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          The email address provided already exists. Please use a different
          email.
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignUp;
