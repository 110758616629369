import AppConsts from "../../lib/constants/appConst";
import http from "../httpService";
import type AppUser from "./AppUser";

class AuthService {
  private readonly userServiceURL = `user/authenticate`;

  public async login(): Promise<any> {
    const result = await http.post(
      "api/services/app/Account/IsTenantAvailable"
    );
    return result.data.result;
  }

  public async register(registerInput: any): Promise<any> {
    const result = await http.post(
      `https://${AppConsts.auth0Domain}/dbconnections/signup`,
      registerInput
    );
    return result.data;
  }

  public async authenticate(auth0Id: string): Promise<any> {
    const result = await http.post(`${this.userServiceURL}`, {
      auth0Id,
    });

    return result.data as AppUser;
  }
}

export default new AuthService();
