class ChildInformationDTO{
    // id?:number | null;
    firstName: string;
    lastName:string;
    email:string;
    description:string;

    constructor(data:any = {}){
        // this.id = data.id || null;
        this.firstName = data.firstName || "";
        this.lastName = data.lastName || "";
        this.email = data.email || "";
        this.description = data.description || "";
    }
}
export default ChildInformationDTO;