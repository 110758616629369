import { useRoutes } from "react-router-dom";
import { JobPost } from "../scenes/employer/jobs/jobs.new";
import { AppLayout } from "./Layouts/AppLayout";
import { PublicLayout } from "../scenes/public/public.layout";
import { JobSearch } from "../scenes/public/jobSearch/jobSearch";
import AutoLogin from "./Auth/AutoLogin";
import ManageJobsPage from "../scenes/employer/jobs/ManageJobsPage";
import AboutPage from "../scenes/public/home/AboutPage";
import ManageJobApplicants from "../scenes/employer/jobDetails/ManageJobApplicants";
import TeenProfile from "../scenes/TeenProfile";
import ChildInformation from "../scenes/ChildInformationForm";
import SignUp from "../scenes/public/SignUp";
import Profile from "../scenes/public/Profile";
import ChildInfo from "../scenes/ChildInfo";
import Authorize from "./Auth/Authorize";
import EmailNotVerified from "./Auth/EmailNotVerified";
import {
  ABOUT,
  CHILD_INFO,
  EMAIL_NOT_VERIFIED,
  JOB_POST,
  LOGIN,
  PROFILE,
  TEEN_PROFILE,
} from "../lib/constants/navigationUrls";
import UserProfile from "./UserProfile";
import JobseekerSearch from '../scenes/employer/jobseekersSearch/JobseekerSearch';
import EmployerParent from "../scenes/public/EmployerParent";
import ParentEmployer from "../scenes/public/ParentEmployer";




export const AppRoutes = () => {
  return useRoutes([
    {
      path: LOGIN,
      element: <AutoLogin />,
    },
    {
      path: "/search",
      element: <JobSearch />,
    },
    {
      path: EMAIL_NOT_VERIFIED,
      element: <EmailNotVerified />,
    },
    {
      path: "/authorize",
      element: <Authorize />,
    },
    {
      path: "",
      element: <PublicLayout />,
    },
    {
      path: ABOUT,
      element: <AboutPage />,
    },
    {
      path: TEEN_PROFILE,
      element: <TeenProfile />,
    },
    {
      path: PROFILE,
      element: <Profile />,
    },
    {
      path: CHILD_INFO,
      element: <ChildInfo />,
    },

    {
      path: "sign-up",
      element: <SignUp />,
    },

    {
      path: "employer",
      element: <AppLayout />,
      children: [
        {
          path: "jobs",
          element: <ManageJobsPage />,
        },
        {
          path: JOB_POST,
          element: <JobPost />,
        },
        {
          path: "employer-profile",
          element: <UserProfile/>,
        },
        {
          path: "jobs/:id",
          element: <ManageJobApplicants />,
        },
        // {
        //   path: "jobseeker-search",
        //   element: <JobseekerSearch />
        // },
        {
          path: "employer-parent",
          element: <EmployerParent/>
        },
       
      ],
    },
    {
      path: "teen",
      element: <AppLayout />,
      children: [
        {
          path: "job-search",
          element: <JobSearch/>,
        },
        {
          path: "applications",
          element: <ManageJobsPage />,
        },
        {
          path: "profile",
          element: <UserProfile/>,
        },
      ],
    },
    {
      path: "parent",
      element: <AppLayout />,
      children: [
        {
          path: "applications",
          element: <ManageJobsPage />,
        },
        {
          path: "profile",
          element: <UserProfile/>,
        },
        {
          path: "parent-employer",
          element: <ParentEmployer/>
        },
      ],
    },
  ]);
};
