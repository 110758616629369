import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useNavigate } from "react-router-dom";
import { ABOUT } from "../../../lib/constants/navigationUrls";

function Footer() {
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        // marginTop: 'calc(10% + 60px)',
        width: "100%",

        position: "fixed",
        bottom: 0,
        backgroundColor: "#121211",
      }}
      component="footer"
    >
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div>
          <Typography
            sx={{ color: "grey", textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              navigate(ABOUT);
            }}
          >
            About Us
          </Typography>
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            <IconButton>
              <a
                href="https://www.facebook.com/profile.php?id=100093686730256"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon sx={{ color: "grey" }} />{" "}
              </a>
            </IconButton>
            <IconButton>
              <a
                href="https://instagram.com/trravo4u"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <InstagramIcon sx={{ color: "grey" }} />{" "}
              </a>
            </IconButton>
            <IconButton
              sx={{ color: "grey" }}
              // color="info"
            >
              <a
                href="https://twitter.com/Trravo4u"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon sx={{ color: "grey" }} />
              </a>
            </IconButton>
          </Stack>
        </div>
      </Box>
      {/* <Divider/> */}
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
          mb: 1,
        }}
      >
        <Typography sx={{ color: "grey", fontSize: "10px" }}>
          Copyright Act ©2023
        </Typography>
      </Box>
    </Paper>
  );
}

export default Footer;
