import { Alert, Button, Snackbar, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RoleId } from "../../../lib/util/common/registration/utils";
import { getAppliedJobs } from "../../../redux/features/jobSlice";
import { currentUser } from "../../../redux/features/userSlice";
import type AppUser from "../../../services/auth/AppUser";
import type JobDTO from "../../../services/job/dtos/job.dto";
import JobCard from "../../teenager/JobCard";
import { Waypoint } from "react-waypoint";

interface Props {
  deleteJob?: Function;
  edit?: boolean;
  updateJobs?: Function;
  loadMore: Function;
}

export const JobsList = ({ updateJobs, deleteJob, loadMore }: Props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [job, setJob] = useState<JobDTO>();
  const [type, setType] = useState<string>("edit");
  const appUser = useSelector(currentUser) as unknown as AppUser;
  const currentRole = appUser.user.currentRole;
  const navigate = useNavigate();

  const handleClickOpen = async (job: JobDTO, type: string) => {
    navigate(`${job.id}`);
    setJob(job);
    setType(type);
  };
  const jobs = useSelector(getAppliedJobs);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message="Job Applied"
      >
        <Alert
          onClose={() => {
            setSnackbarOpen(false);
          }}
          severity="success"
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          Job deleted successfully.
        </Alert>
      </Snackbar>

      <Stack sx={{ position: "relative", right: "10vw", ml: "6rem" }}>
        <Stack
          sx={{ m: 5 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            You can manage your jobs here
          </Typography>
          {currentRole === RoleId.EMPLOYER && (
            <Button
              variant="contained"
              sx={{ fontSize: 15, fontWeight: "bold" }}
              onClick={() => {
                navigate("/employer/jobpost");
              }}
            >
              Post a Job
            </Button>
          )}
        </Stack>
        <>
          {jobs?.map((job: any, i: number) => (
            <JobCard
              key={job.title}
              jobDetails={{
                title: job.title,
                firstName: job.firstName,
                lastName: job.lastName,
                price: job.price,
                priceType: job.priceType,
                description: job.description,
                address: job.address,
                employer: job.employer,
                createdAt: job.createdAt,
                startDate: job.startDate,
                endDate: job.endDate,
                status: job.status,
              }}
            >
              {i === jobs.length - 3 && (
                <Waypoint
                  onEnter={() => {
                    loadMore();
                  }}
                />
              )}
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "right" }}
              >
                {currentRole === RoleId.EMPLOYER ? (
                  <Button
                    onClick={() => {
                      handleClickOpen(job, "edit");
                    }}
                    variant="outlined"
                  >
                    View Applicants
                  </Button>
                ) : (
                  <Button disabled variant="outlined">
                    Applied
                  </Button>
                )}
              </Stack>
            </JobCard>
          ))}
        </>
      </Stack>
    </>
  );
};
