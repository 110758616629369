class TeenProfileDTO {
  id?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  interests: string;
  resume: File | null;
  works: File[];

  constructor(data: any = {}) {
    this.id = data.id ?? null;
    this.firstName = data.firstName ?? "";
    this.lastName = data.lastName ?? "";
    this.email = data.email ?? "";
    this.description = data.description ?? "";
    this.interests = data.interests || "";
    this.resume = data.resume ?? null;
    this.works = data.works ?? null;
  }
}
export default TeenProfileDTO;
