import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import trravoLogo from "../../assets/trravo1.png";
import LogoutButton from "../../components/Auth/Logout";

const RegistrationHeader: FC = () => {
  const navigate = useNavigate();
  return (
    <AppBar position="relative" sx={{ height: "4.5rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Toolbar>
          <img
            src={trravoLogo}
            alt="trravo1"
            style={{ height: "4rem", width: "10rem" }}
            onClick={() => {
              navigate("/");
            }}
          />
        </Toolbar>

        <Button
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            color: "white",
            border: "1px solid white",
            marginRight: "1rem",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </Box>
    </AppBar>
  );
};

export default RegistrationHeader;
