import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { type JobSearchQueryDTO } from "../../../services/job/dtos/jobSearchQuery.dto";
import { validateName, validateZipCode } from "../../../lib/util/common/utils";
import SubscriptionDialog from "./SubscriptionDialog";
import { SEARCH } from "../../../lib/constants/navigationUrls";

export const CssTextField = styled(TextField)({
  color: "white",
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const searchBtn = {
  backgroundColor: "white",
  border: "1px solid white",
  color: "rgb(158, 128, 226)",
  fontWeight: "bold",
  height: "55px",
};
interface FilterProps {
  onSubmit: any;
}

const HomeFilters = ({ onSubmit }: FilterProps) => {
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] =
    useState<boolean>(false);

  const [filter, setFilter] = useState<JobSearchQueryDTO>({});
  const { title, zipCode } = filter;
  const [titleError, setTitleError] = useState<string | null>(null);
  const [zipError, setZipError] = useState<string | null>(null);
  const navigate = useNavigate();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    // cloning to a new object
    const data = { ...filter };

    data.title = target.value;
    setFilter(data);
  };

  const onChangeZipCode = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^\u0030-\u0039]/g, "");

    if (value.length <= 5) {
      const data = { ...filter, zipCode: value };
      setFilter(data);
    }

    if (value.length === 0 || value.length === 5) {
      setZipError(null);
    } else {
      setZipError("Enter proper 5 digit Zipcode");
    }
  };

  const onInputFocusOut = (event: any) => {
    const { target } = event;
    const { name, value } = target;
    switch (name) {
      case "title":
        setTitleError(validateTitle(value));
        break;

      case "zipCode":
        setZipError(validateZip(value));
        break;

      default:
        break;
    }
  };

  const validateTitle = (value: string): string | null => {
    if (value === "") {
      return null;
    }

    let message = null;
    if (validateName(value) == null) {
      message = "Numbers and Special Characters are not allowed";
    }
    return message;
  };

  const validateZip = (value: string): string | null => {
    if (value === "") {
      return null;
    }

    let message = null;
    if (value?.length !== 5 && validateZipCode(value) != null) {
      message = "Enter proper 5 digit Zipcode";
    } else if (value?.length !== 5 && validateZipCode(value) == null) {
      message = "Enter 5 digit Zipcode ";
    }
    return message;
  };

  const isFormValid = (filterValidation: JobSearchQueryDTO): boolean => {
    const { title } = filterValidation;

    const titleError = validateTitle(String(title));
    setTitleError(titleError);

    return titleError === null;
  };

  const onSubmitClick = async () => {
    if (isFormValid(filter)) {
      const queryParams = new URLSearchParams();
      const ti = title ?? "";
      const zi = zipCode ?? "";
      queryParams.append("title", String(ti));
      queryParams.append("zipCode", String(zi));

      try {
        onSubmit();
        const titleError = validateTitle(String(title));
        setTitleError(titleError);

        if (titleError === null) {
          if (zipCode != null) {
            const zipError = validateZip(String(zipCode));
            setZipError(zipError);
          }

          if (zipError === null) {
            navigate(`${SEARCH}?${queryParams.toString()}`);
          }
        }
      } catch (error) {}
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < 600 && window.innerHeight < 1000
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600 && window.innerHeight < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMiniScreen, setIsMiniScreen] = useState(
    window.innerWidth < 300 && window.innerHeight < 1000
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMiniScreen(window.innerWidth < 300 && window.innerHeight < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth > 751 &&
      window.innerWidth < 950 &&
      (window.innerHeight < 1200 || window.innerHeight < 1400)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(
        window.innerWidth > 751 &&
          window.innerWidth < 950 &&
          (window.innerHeight < 1200 || window.innerHeight < 1400)
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeBox = () => {
    setIsSubscribeModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: isMediumScreen ? "space-evenly" : "",
        height: isSmallScreen || isMediumScreen ? "25rem" : "8rem",
        marginTop: isSmallScreen ? "10px" : "",
      }}
    >
      <Grid
        container
        sx={{ px: isSmallScreen ? 5 : 10 }}
        spacing={{ xs: 2, md: 3 }}
        direction="row"
      >
        <Grid item xs={12} md={isMediumScreen ? 12 : isSmallScreen ? 6 : 4}>
          <CssTextField
            sx={{
              borderRadius: 2,
              color: "white",
              borderColor: "white",
              label: {
                color: "rgba(255, 255, 255, 0.5)",
              },
            }}
            id="title-search-bar"
            placeholder="Enter Job Title"
            label="Enter Job Title"
            name="title"
            fullWidth
            value={title}
            onChange={onInputChange}
            onBlur={onInputFocusOut}
            error={titleError !== null}
            helperText={<span style={{ color: "white" }}>{titleError}</span>}
          />
        </Grid>
        <Grid item xs={12} md={isMediumScreen ? 12 : isSmallScreen ? 6 : 4}>
          <CssTextField
            sx={{
              borderRadius: 2,
              label: {
                color: "rgba(255, 255, 255, 0.5)",
              },
            }}
            id="zip-code-search-bar"
            placeholder="Enter Zip Code"
            label="Enter Zip Code"
            fullWidth
            name="zipCode"
            value={zipCode}
            onChange={onChangeZipCode}
            error={zipError !== null}
            helperText={<span style={{ color: "white" }}>{zipError}</span>}
            inputProps={{
              maxLength: 5,
            }}
          />
        </Grid>
        <Grid item xs={12} md={isSmallScreen ? 6 : 4}>
          <Button
            id="find-jobs-btn"
            sx={searchBtn}
            size="large"
            variant="contained"
            onClick={() => {
              void onSubmitClick();
            }}
          >
            Find Jobs
          </Button>
        </Grid>

        <Stack
          direction={"row"}
          gap={isMediumScreen ? 2 : 1}
          alignContent={"center"}
          justifyContent={"center"}
          sx={{ width: "46rem", marginTop: "1rem" }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              color: "white",
              textDecoration: "underline",
              justifyContent: "center",
              alignItems: "center",
              fontSize: isMediumScreen ? "1.5rem" : "1rem",
            }}
            id="subscribe-title"
            onClick={() => {
              setIsSubscribeModalOpen(true);
            }}
          >
            SignUp
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: isMediumScreen
                ? "1.5rem"
                : isMiniScreen
                ? "12px"
                : "1rem",
            }}
          >
            to receive SMS notification on new job postings in your neighborhood
          </Typography>
        </Stack>
      </Grid>

      {isSubscribeModalOpen && (
        <SubscriptionDialog
          isOpen={isSubscribeModalOpen}
          onClose={() => {
            setIsSubscribeModalOpen(false);
          }}
          setIsSubscribeModalOpen={closeBox}
        />
      )}
    </Box>
  );
};

export default HomeFilters;
