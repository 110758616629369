import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../lib/constants/navigationUrls";

const LoginButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(LOGIN);
      }}
      sx={{ border: "1px solid white", color: "white", background: "#9E80E2" }}
    >
      Post Job
    </Button>
  );
};

export default LoginButton;
