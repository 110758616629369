import { ThemeOptions } from "@mui/material";

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#9a81dc",
    },
    secondary: {
      main: "#484848",
    },
    info: {
      main: "#9a81dc",
    },
  },
};
