import type UserProfileDTO from "../UserProfile/UserProfile.dto";
import http from "../httpService";
import type ProfileDTO from "./profile.dto";

class ProfileService {
  public async get(id: string): Promise<ProfileDTO> {
    const result = await http.get(`/user/profile/${id}`);
    return result.data as ProfileDTO;
  }

  public async update(id: string, data: ProfileDTO): Promise<ProfileDTO> {
    const result = await http.patch(`/user/profile/${id}`, data);
    return result.data as ProfileDTO;
  }

  // user profile card data after login
  public async getProfileData(id: string): Promise<UserProfileDTO> {
    const result = await http.get(`/user/profile/${id}`);
    return result.data as UserProfileDTO;
  }

  // user manage profiles update API
  public async updateProfile(
    id: string,
    details: UserProfileDTO
  ): Promise<UserProfileDTO> {
    const result = await http.patch(`/profile/${id}`, details);
    return result.data as UserProfileDTO;
  }

  // Hybrid user get roles
  public async getUserRoles(id: string): Promise<any> {
    const result = await http.get(`/user/roles/${id}`);
    return result.data;
  }

  //Toggle switch or Hybrid user
  public async toggleSwitch(id: string,roleId:number | undefined): Promise<any> {
    const result = await http.post(`/switch/${id}`,{roleId});
    return result.data;
  }

}

export default new ProfileService();
